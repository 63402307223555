import Vue from "vue";
import VueI18n from "vue-i18n";
import { DEFAULT_LANGUAGE, FALLBACK_LANGUAGE } from "@/utils/i18Defaults";
import en from "@/locales/en.json";
import es from "@/locales/es.json";

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: DEFAULT_LANGUAGE,
  fallbackLocale: FALLBACK_LANGUAGE,
  messages: { en, es }
});

export default i18n;
