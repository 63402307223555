import axios from "axios";

const apiConfig = {
  grant_type: `${process.env.VUE_APP_HDRUPAL_GRANT_TYPE}`,
  baseURI: `${process.env.VUE_APP_HDRUPAL_BASE_URL}`
};

export default {
  async getGeneralContent() {
    const params = new URLSearchParams();
    params.append(
      "include",
      "field_icons_list,field_cc_content_list,field_image_list,field_box_content,field_ccb_gallery_content,field_ccs_items,field_gallery_item,field_team_list"
    );
    params.append("jsonapi_include", "1");
    params.append(
      "fields[paragraph--icon_item]",
      "field_pg_link,field_pg_image_text,field_pg_title_w_format"
    );
    params.append(
      "fields[paragraph--box_content]",
      "field_pg_description,field_pg_image_text,field_pg_link,field_pg_title_w_format"
    );
    params.append(
      "fields[paragraph--image_element]",
      "field_pg_image_text,field_pg_title_w_format"
    );
    params.append(
      "fields[paragraph--list_item]",
      "field_pg_link,field_pg_title"
    );
    params.append(
      "fields[paragraph--gallery_list]",
      "field_pg_description,field_pg_image_list,field_pg_title,field_pg_image_description,field_pg_image_list_description"
    );
    params.append(
      "fields[paragraph--member_item]",
      "field_pg_description,field_pg_image_text,field_pg_sub_title,field_pg_title"
    );
    params.append("filter[field_gid]", process.env.VUE_APP_HDRUPAL_GID);
    const content = await axios.get(
      apiConfig.baseURI + "/kis/NMqNxPm8tpGZ3Rwj/api/node/general_page",
      {
        params
      }
    );

    return content;
  },

  async getGeneralContentDraft() {
    const params = new URLSearchParams();
    params.append("fields[node--general_page]", "id");
    params.append("resourceVersion", "rel:latest-version");
    params.append("filter[field_gid]", process.env.VUE_APP_HDRUPAL_GID);

    const content = await axios
      .get(apiConfig.baseURI + "/kis/NMqNxPm8tpGZ3Rwj/api/node/general_page", {
        params
      })
      .then(response => {
        if (response.data.data) {
          const newValues = response.data.data.map(item => {
            if (Object.keys(item.links).length === 1) {
              return this.getDraftEntityContent(
                item.id,
                "rel:latest-version"
              );
            }

            return this.getDraftEntityContent(
              item.id,
              "rel:working-copy"
            );
          });
          return Promise.all(newValues);
        }

        return [];
      });
    return {
      data: content
    };
  },

  async getDraftEntityContent(id, status) {
    const params = new URLSearchParams();
    params.append("filter[field_gid]", process.env.VUE_APP_HDRUPAL_GID);
    params.append(
      "include",
      "field_icons_list,field_cc_content_list,field_image_list,field_box_content,field_ccb_gallery_content,field_ccs_items,field_gallery_item,field_team_list"
    );
    params.append("jsonapi_include", "1");
    params.append(
      "fields[paragraph--icon_item]",
      "field_pg_link,field_pg_image_text,field_pg_title_w_format"
    );
    params.append(
      "fields[paragraph--box_content]",
      "field_pg_description,field_pg_image_text,field_pg_link,field_pg_title_w_format"
    );
    params.append(
      "fields[paragraph--image_element]",
      "field_pg_image_text,field_pg_title_w_format"
    );
    params.append(
      "fields[paragraph--list_item]",
      "field_pg_link,field_pg_title"
    );
    params.append(
      "fields[paragraph--gallery_list]",
      "field_pg_description,field_pg_image_list,field_pg_title,field_pg_image_description,field_pg_image_list_description"
    );
    params.append(
      "fields[paragraph--member_item]",
      "field_pg_description,field_pg_image_text,field_pg_sub_title,field_pg_title"
    );
    params.append("resourceVersion", status);

    return await axios
      .get(
        apiConfig.baseURI + "/kis/NMqNxPm8tpGZ3Rwj/api/node/general_page/" + id,
        {
          params
        }
      )
      .then(response => {
        return response.data.data;
      });
  }
};
