<template>
  <header
          id="siteHeaderBox"
          class="site-header" :class="[
          isCookie() ? 'is-cookie-header' : '',
          sticky ? 'sticky' : ''
  ]">
    <div class="wrapper-fluid">
      <div class="row around middle-xs">
        <div class="site-logo">
          <router-link @click.native="sameRoute('Home')" :to="headerLink">
            <img src="@/assets/images/logo.svg" alt="FirstBank Platinum" />
          </router-link>
        </div>
        <button
          class="hamburger hamburger--collapse hidden-xl-none"
          type="button"
          aria-label="Menu"
          aria-controls="navigation"
        >
          <span class="hamburger-box">
            <span class="hamburger-inner"></span>
          </span>
        </button>
        <div class="navbar--collapse" id="navbarCollapse" v-if="isReady">
          <c-main-menu :section-data="content[0]" v-if="content[0]" />
          <language-switch />
          <div class="hidden-xl-none mobile-cinfo">
<!--            <div class="cinfo">-->
<!--              <div class="cinfo-title">{{ $t("visit_us") }}</div>-->
<!--              <div class="cinfo-address">-->
<!--                {{ $t("address_1") }}<br />-->
<!--                {{ $t("address_2") }} <br />-->
<!--                {{ $t("address_3") }}-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="cinfo">-->
<!--              <div class="cinfo-title">{{ $t("contact_us") }}</div>-->
<!--              <div class="cinfo-phone">-->
<!--                  <a href="tel:7877298108">787-729-8108</a>-->
<!--              </div>-->
<!--               <div class="cinfo-phone">-->
<!--                <a href="tel:7877296102">787-729-6102</a>-->
<!--              </div>-->
<!--            </div>-->
            <div class="cinfo">
              <div class="cinfo-title">{{ $t("follow_us") }}</div>
              <div class="cinfo-social">
                <a href="https://www.linkedin.com/company/first-bancorp/" target="_blank"
                  ><i class="fab fa-linkedin-in" aria-hidden="true"></i
                ></a>
                 <a href="https://www.facebook.com/firstbank" target="_blank"
                  ><i class="fa fa-facebook" aria-hidden="true"></i
                ></a>
                 <a href="https://www.instagram.com/firstbank_pr/" target="_blank"
                  ><i class="fa fa-instagram" aria-hidden="true"></i
                ></a>
                <a href="https://twitter.com/firstbankpr" target="_blank"
                  ><i class="fa fa-twitter" aria-hidden="true"></i
                ></a>
                <a href="https://www.youtube.com/FirstBankpr" target="_blank"
                  ><i class="fa fa-youtube" aria-hidden="true"></i
                ></a>
                 


              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="row">

      </div>
    </div>
  </header>
</template>

<script>
import CMainMenu from "@/components/blocks/mainMenu.vue";
import LanguageSwitch from "./LanguageSwitch/Index";
export default {
  name: "Header",
  data: function() {
    return {
      sticky: false,
      default: {
        uuid: "fbb6864e-41be-408b-b468-cb52ed739047",
        nid: 680,
        title: "Main Menu",
        type: "group_menu"
      },
      isReady: false,
      language: this.$i18n.locale,
      headerLink: "/es",
      content: null,
      showMenu: false,
    }
  },
  components: {
    LanguageSwitch,
    CMainMenu
  },
  // created() {
  //   this.loadContent();
  // },
  methods: {
    isCookie(){
      const path = this.$route.name;
      return path === 'Cookies';
    },
    sameRoute: function(data) {
      let body = document.querySelector('body');
      let nav = document.querySelector('.hamburger');
      if(this.$route.name == data ) {
        body.classList.remove('open--nav');
        nav.classList.remove('is-active');
      }
    },
    showMenuCheck(){
      this.showMenu = !this.showMenu;
      const classBody = "fix-menu-mobile";
      if(this.showMenu){
        document.body.classList.add(classBody);
      } else {
        document.body.classList.remove(classBody);
      }
    },
    updateLanguage() {
      this.language = this.$i18n.locale;
      this.headerLink = "/es";
      if (this.language === "en") {
        this.headerLink = "/en";
      }
    },
    getLangContent() {
      let content = null;
      const lang = this.$i18n.locale;
      if (lang === "en") {
        content = this.$store.getters.getContentEN(this.default.uuid);
      } else {
        content = this.$store.getters.getContentES(this.default.uuid);
      }

      return content;
    },

    loadContent: function() {
      const lang = this.$i18n.locale;
      let content = this.getLangContent();
      if (!content) {
        this.$store
                .dispatch("setContent", {
                  type: this.default.type,
                  uuid: this.default.uuid,
                  lang: lang
                })
                .finally(() => {
                  content = this.getLangContent();
                  // console.log("Finally", content);
                  this.isReady = true;
                  this.content = content;
                });
      }
      if (content) {
        this.isReady = true;
        this.content = content;
      }
    }
  },
  watch: {
    $route() {
      this.loadContent();
      this.updateLanguage();
      this.showMenu = false;
    }
  },
  // mounted() {
  //   let message = document.getElementById('alertMessageBox');
  //   let menu = document.getElementById('navbarCollapse');
  //   let header = document.getElementById('siteHeaderBox');
  //
  //   console.log("Window width", window.screen.width);
  //   // let height = 65;
  //   let marginTopGeneral = header.offsetHeight;
  //   let messageHeight = 0;
  //   if(message){
  //     messageHeight = message.offsetHeight;
  //   }
  //   marginTopGeneral = marginTopGeneral + messageHeight;
  //
  //   // let marginTop = message.offsetHeight + height;
  //   if(window.screen.width > 1275){
  //     marginTopGeneral = 0;
  //   }
  //   menu.style.marginTop = marginTopGeneral + 'px';
  //   window.document.onscroll = () => {
  //     const localThis = this;
  //     if(window.scrollY >= 50){
  //       localThis.sticky = true;
  //       marginTopGeneral = header.offsetHeight;
  //       if(window.screen.width > 1275){
  //         marginTopGeneral = 0;
  //       }
  //       menu.style.marginTop = marginTopGeneral + 'px';
  //     } else {
  //       localThis.sticky = false;
  //       let marginTopGeneral = messageHeight + header.offsetHeight;
  //       if(window.screen.width > 1275){
  //         marginTopGeneral = 0;
  //       }
  //       menu.style.marginTop = marginTopGeneral + 'px';
  //     }
  //   }
  // }
}
</script>
<style lang="scss">
  .nav-dropdown .dropdown-menu{
    @media(min-width: 1024px){
      margin-top: 0!important;
      padding-top: 1px!important;
    }
  }
</style>
