<template>
  <section v-if="isReady" class="playeros-header-container">
    <div class="wrapper">
      <h2
          v-if="content.field_header_title"
          v-html="content.field_header_title.value"
          class="display--1 no-break"
      ></h2>
      <div class="content-container" v-if="content.body">
        <div class="content" v-html="this.helpers.setTextAreaField(content.body)">
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import * as contentAPI from "@/api/content";
export default {
  name: "ArtSeriesTitleDescription2024",
  data() {
    return {
      default: {
        uuid: "3cc5199c-4f70-465e-8345-9c2de5c4f6c1",
        nid: 1013,
        title: "Restrospective",
        type: "fb_block_section"
      },
      isReady: false,
      templateName: "retrospective-header",
      language: this.$i18n.locale,
      content: null,
      helpers: this.$helperList
    };
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    updateLanguage() {
      this.language = this.$i18n.locale;
      this.headerLink = "/es";
      if (this.language === "en") {
        this.headerLink = "/en";
      }
      this.parentSite= 'https://www.1firstbank.com/pr/' + this.$i18n.locale;
    },
    getContent() {
      // const helpers = this.helpers;
      this.isReady = false;
      let content = null;
      contentAPI
        .getContent({
          type: this.default.type,
          uuid: this.default.uuid,
          lang: this.language
        })
        .then(data => {
          content = data.data.data;
          this.content = content;
        })
        .catch(error => {
          console.log("ERROR ON setContent function", error);
          content = null;
        })
        .finally(() => {
          if (content) {
            this.isReady = true;
          }
          // this.content = content;
          // this.isReady = true;
        });
    },
  },
  watch: {
    $route() {
      this.language = this.$i18n.locale;
      this.getContent();
      this.updateLanguage();
    }
  },
  created() {
    this.getContent();
    this.updateLanguage();
  }
};
</script>

<style scoped lang="scss">

</style>
