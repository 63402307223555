import $http from "@/services/axios";
import { SUPPORTED_LANGUAGES } from "@/utils/i18Defaults";

async function getData(lang, templateName) {
  const contentURI = `${lang}/${process.env.VUE_APP_HDRUPAL_CONTENT_URI}`;
  if (templateName === "") {
    console.log(templateName);
  }
  const params = {
    jsonapi_include: "1",
    "filter[langcode]": lang,
    "filter[field_gid]": process.env.VUE_APP_HDRUPAL_GID
  };

  return await $http.get(`${contentURI}`, {
    params
  });
}

async function getGeneralContent(type, id, lang) {
  const contentURI = `/${lang}/${process.env.VUE_APP_HDRUPAL_CONTENT_URI}${type}`;
  let params = {
    jsonapi_include: "1"
  };
  if (id) {
    params = {
      jsonapi_include: "1",
      "filter[field_website.name]": id,
      "filter[field_gid]": process.env.VUE_APP_HDRUPAL_GID
      // 'filter[website][condition][path]': 'field_website.name',
      // 'filter[website][condition][value]': id,
    };
  }
  // console.log('axios call', contentURI, params, id, lang)
  return await $http.get(contentURI, { params });
}

async function getContent(node) {
  let contentURI = "";

  let params = {
    jsonapi_include: "1"
  };

  if (node.uuid) {
    contentURI = `${node.lang}/${process.env.VUE_APP_HDRUPAL_CONTENT_URI}${node.type}/${node.uuid}`;
  }

  if (node.template == "playeros" || node.template == "retrospective") {
    contentURI = `${node.lang}/${process.env.VUE_APP_HDRUPAL_CONTENT_URI}${node.type}`;
    params = {
      jsonapi_include: "1",
      "filter[field_gid]": process.env.VUE_APP_HDRUPAL_GID,
      "filter[field_custom_template]": node.template,
      "sort": "field_sort"
    };
    // console.log("Playeros", params);
  }

  if (process.env.VUE_APP_DRAFT === "1" && node.type === "fb_general_page") {
    params = {
      jsonapi_include: "1",
      resourceVersion: "rel:working-copy",
      "filter[field_gid]": process.env.VUE_APP_HDRUPAL_GID
    };
  }

  return await $http.get(`${contentURI}`, {
    params
  });
}

async function getBlocks(node) {
  let contentURI = "";

  let params = {
    jsonapi_include: "1"
  };

  contentURI = `${node.lang}/${process.env.VUE_APP_HDRUPAL_CONTENT_URI}${node.type}`;
  params = {
    jsonapi_include: "1",
    "filter[field_gid]": process.env.VUE_APP_HDRUPAL_GID,
    "filter[field_template]": "modal",
    "sort": "field_sort"
  };

  return await $http.get(`${contentURI}`, {
    params
  });
}

async function getDraftData(locale, templateName) {
  // console.log("GetDraftData", locale, templateName);
  const contentURI = `${locale}/${process.env.VUE_APP_HDRUPAL_CONTENT_URI}`;
  if(templateName == 'only-test') {
    console.log(templateName);
  }
  const params = {
    "fields[node--blog]": "id,links",
    "filter[langcode]": locale,
    "filter[field_gid]": process.env.VUE_APP_HDRUPAL_GID
  };

  const contentIdList = await $http
    .get(`${contentURI}`, {
      params
    })
    .then(response => {
      if (response.data.data) {
        // console.log("DRAFT", response.data);
        const newValues = response.data.data.map(item => {
          return getDraftEntityContent(locale, item.id, "rel:working-copy");
        });
        return Promise.all(newValues);
      }

      return [];
    });
  return {
    data: contentIdList
  };
}

async function getDraftEntityContent(locale, id, status) {
  const contentURI = `${locale}/${process.env.VUE_APP_HDRUPAL_CONTENT_URI}/${id}`;

  const params = {
    jsonapi_include: "1",
    // "page[limit]": "6",
    // sort: "-field_date",
    resourceVersion: `${status}`,
    "filter[field_gid]": process.env.VUE_APP_HDRUPAL_GID
  };

  return await $http.get(`${contentURI}`, { params }).then(response => {
    return response.data.data;
  });
}

async function getContentDraft(templateName) {
  return await Promise.all(
    SUPPORTED_LANGUAGES.map(lang => {
      return getDraftData(lang, templateName);
    })
  );
}
export { getContent, getData, getContentDraft, getGeneralContent, getBlocks};
