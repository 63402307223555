import $http from "@/services/axios";

async function urlMap() {
  const params = {
    gid: `${process.env.VUE_APP_HDRUPAL_GID}`
  };

  return await $http.get(`${process.env.VUE_APP_HDRUPAL_MAP_URI}`, {
    params
  });
}
export { urlMap };
