<template>
  <section class="ready-to-start">
    <div class="wrapper">
      <div class="cta contact-us-block">
        <div class="cta-desc">
          <h2 class="display--1 no-break">
            {{ $t("footerBlock.title_1") }} <span>{{ $t("footerBlock.title_2") }}</span>
          </h2>
          <p>{{ $t("footerBlock.text") }}</p>
        </div>
        <div class="cta-btn">
          <router-link :to="contactUs" class="white-button">
            {{ $t("footerBlock.btn_text") }}
          </router-link>
        </div>
      </div>

      <div class="cblock hidden-md-and-down">
<!--        <div class="visit&#45;&#45;us cc&#45;&#45;col">-->
<!--          <div class="cblockTitle">{{ $t("footerBlock.visit_label") }}</div>-->
<!--          <div class="cblockDesc">-->
<!--            {{ $t("footerBlock.address_1") }} <br />-->
<!--            {{ $t("footerBlock.address_2") }}<br />-->
<!--            Condado, 00907-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="contact&#45;&#45;us cc&#45;&#45;col">-->
<!--          <div class="cblockTitle">{{ $t("footerBlock.contact_label") }}</div>-->
<!--          <div class="cblockDesc">-->
<!--            <a href="tel:7877298108">787-729-8108</a>-->
<!--          </div>-->
<!--          <div class="cblockDesc">-->
<!--            <a href="tel:7877296102">787-729-6102</a>-->
<!--          </div>-->
<!--        </div>-->
        <div class="follow-us cc--col">
<!--          <div class="cblockTitle">{{ $t("footerBlock.follow_label") }}</div>-->
            <a href="https://www.linkedin.com/company/firstbankpr/" class="fab fa-linkedin-in social-link" aria-hidden="true"></a>
            <a href="https://www.facebook.com/firstbank" class="fa fa-facebook social-link" aria-hidden="true"></a>
            <a href="https://www.instagram.com/firstbank_pr/" class="fa fa-instagram social-link" aria-hidden="true"></a>
            <a href="https://twitter.com/firstbankpr" class="fa fa-twitter social-link" aria-hidden="true"></a>
            <a href="https://www.youtube.com/FirstBankpr" class="fa fa-youtube social-link" aria-hidden="true"></a>
            
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'BottomContent',
  data() {
    return {
      bankLink: '/en/banking',
      contactUs: '/en/contact-us',
      language: this.$i18n.locale,
    }
  },
  methods: {
    updateLanguage() {
      this.language = this.$i18n.locale;
      this.bankLink = "/es/banca";
      this.contactUs = "/es/contactenos";
      if (this.language === "en") {
        this.bankLink = "/en/banking";
        this.contactUs = '/en/contact-us';
      }
    },
  },
  computed: {
    isContactUs() {
      const pathData = this.$route.path;
      if(pathData.includes("contact")){
        return true;
      }
      if(this.$route.path === '/contact-us'){
        return true
      }
      return false
    }
  },
  watch: {
    $route() {
      this.language = this.$i18n.locale;
      this.updateLanguage();
    }
  },
  created() {
    this.updateLanguage();
  },
}
</script>

<style scoped lang="scss">
  /*.social-link{*/
  /*  &:hover{*/
  /*    text-decoration: none!important;*/
  /*  }*/
  /*}*/
  /*.contact-us-block{*/
  /*  .display--1{*/
  /*    margin-right: 30px;*/
  /*  }*/
  /*  .display--1.no-break{*/
  /*    @media(max-width: 690px){*/
  /*      max-width: 500px;*/
  /*      margin-left: auto;*/
  /*      margin-right: auto;*/
  /*    }*/
  /*    @media(max-width: 390px){*/
  /*      max-width: 270px;*/
  /*    }*/
  /*  }*/
  /*  p{*/
  /*    @media(max-width: 500px){*/
  /*      max-width: 340px;*/
  /*      margin-left: auto;*/
  /*      margin-right: auto;*/
  /*    }*/
  /*  }*/
  /*}*/
  /*.white-button{*/
  /*  text-align: center;*/
  /*}*/
</style>
