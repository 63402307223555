<template>
  <section class="ready-to-start" v-if="isReady">
    <div class="wrapper">
      <div v-if="isContactUs" class="cta contact-us-block">
        <div class="cta-desc">
          <h2
            class="display--1 no-break"
            v-if="content.field_header_title"
            v-html="content.field_header_title.value"
          ></h2>
          <p v-if="content.body" v-html="content.body.value"></p>
        </div>
        <div class="cta-btn" v-if="content.field_link">
          <router-link
            :to="helpers.getMenuUrl(content.field_link.uri)"
            class="white-button"
          >
            {{ content.field_link.title }}
          </router-link>
        </div>
      </div>
      <div class="cblock hidden-md-and-down">
        <div class="follow-us cc--col">
          <!--          <div class="cblockTitle">{{ content.field_list_title }}</div>-->
          <a
            :href="helpers.getMenuUrl(item.field_pg_link.uri)"
            class="fab social-link"
            aria-hidden="true"
            :class="[
              index === 0 ? 'first' : '',
              index + 1 === content.field_items_1.length ? 'last' : '',
              index >= 0 ? 'item-' + index : '',
              item.field_pg_class
            ]"
            v-for="(item, index) in content.field_items_1"
            :key="item.id"
          ></a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import * as contentAPI from "@/api/content";
export default {
  name: "BottomContentContact",
  data() {
    return {
      bankLink: "/en/banking",
      contactUs: "/en/contact-us",
      language: this.$i18n.locale,
      default: {
        uuid: "41f0ae24-3282-44d3-a802-34e6f23544cb",
        nid: 703,
        title: "Footer",
        type: "fb_block_section"
      },
      isReady: false,
      content: null,
      helpers: this.$helperList
    };
  },
  methods: {
    getContent() {
      // const helpers = this.helpers;
      this.isReady = false;
      let content = null;
      contentAPI
        .getContent({
          type: this.default.type,
          uuid: this.default.uuid,
          lang: this.language
        })
        .then(data => {
          content = data.data.data;
          this.content = content;
        })
        .catch(error => {
          console.log("ERROR ON setContent function", error);
          content = null;
        })
        .finally(() => {
          if (content) {
            this.isReady = true;
          }
          // this.content = content;
          // this.isReady = true;
        });
    },
    updateLanguage() {
      this.language = this.$i18n.locale;
      this.bankLink = "/es/banca";
      this.contactUs = "/es/contactenos";
      if (this.language === "en") {
        this.bankLink = "/en/banking";
        this.contactUs = "/en/contact-us";
      }
    }
  },
  computed: {
    isContactUs() {
      const pathData = this.$route.path;
      if (pathData.includes("contact")) {
        return true;
      }
      if (this.$route.path === "/contact-us") {
        return true;
      }
      return false;
    }
  },
  watch: {
    $route() {
      this.language = this.$i18n.locale;
      this.updateLanguage();
      this.getContent();
    }
  },
  created() {
    this.updateLanguage();
    this.getContent();
  }
};
</script>

<style scoped lang="scss">

</style>
