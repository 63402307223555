import Vue from "vue";
import Vuex from "vuex";
import api from "../utils/api.js";
import { urlMap } from "@/api/urlMap";
import { menu } from "@/api/menu";
import * as content from "@/api/content";
import * as messages from "@/utils/messages";
import * as contentAPI from "@/api/content";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    urlMap: null,
    contentES: [],
    contentEN: [],
    blocksES: [],
    blocksEN: [],
    contentGeneralES: {},
    contentGeneralEN: {},
    contentKeys: [],
    menu: null,
    pageTransition: {
      name: "router-view",
      mode: "in-out"
    },
    generalContent: [],
    hDrupalBaseUrl: `${process.env.VUE_APP_HDRUPAL_BASE_URL}`,
    messagesContent: null
  },
  mutations: {
    SET_MENU(state, payload) {
      state.menu = payload;
    },
    SET_BLOCKS_EN(state, payload) {
      state.blocksEN = payload;
    },
    SET_BLOCKS_ES(state, payload) {
      state.blocksES = payload;
    },
    SET_CONTENT_EN(state, payload) {
      let currentIndex = null;
      state.contentEN.forEach(function(current, index) {
        if (current.id === payload.id) {
          currentIndex = index;
        }
      });
      // console.log("Current INDEX", state.contentEN, currentIndex);
      if (currentIndex) {
        state.contentEN[currentIndex] = payload;
      } else {
        state.contentEN.push(payload);
      }
    },
    SET_CONTENT_ES(state, payload) {
      let currentIndex = null;
      state.contentES.forEach(function(current, index) {
        if (current.id === payload.id) {
          currentIndex = index;
        }
      });
      if (currentIndex) {
        state.contentES[currentIndex] = payload;
      } else {
        state.contentES.push(payload);
      }
    },
    SET_GENERAL_CONTENT_EN(state, payload) {
      payload.forEach(element => {
        state.contentGeneralEN[element.id] = element;
        state.contentKeys.push({
          id: element.id,
          templateId: element.field_custom_template
        });
      });
    },
    SET_GENERAL_CONTENT_ES(state, payload) {
      payload.forEach(element => {
        state.contentGeneralES[element.id] = element;
        state.contentKeys.push({
          id: element.id,
          templateId: element.field_custom_template
        });
      });
    },
    SET_URL_MAP(state, payload) {
      this.state.urlMap = payload;
    },
    SET_MESSAGES(state, { pageContent }) {
      this.state.messagesContent = pageContent;
    },
    setPageTransition(state, value) {
      if ("default" === value) {
        Vue.set(state, "pageTransition", {
          name: "router-view",
          mode: "in-out"
        });
      }
      if ("back" === value) {
        Vue.set(state, "pageTransition", {
          name: "router-view-back",
          mode: ""
        });
      }
    },
    setGeneralContent(state, data) {
      state.generalContent = data;
    }
  },
  actions: {
    async setMessages({ commit }) {
      await messages.getContent().then(data => {
        // console.log("DATA", data);
        const pageContent = data.data.data;
        commit("SET_MESSAGES", { pageContent });
      });
    },
    async setBlocksES({ commit }) {
      // console.log("node", node);
      contentAPI
        .getBlocks({
          type: "fb_block_section",
          lang: "es"
        })
        .then(data => {
          if (data.data.data) {
            commit("SET_BLOCKS_ES", data.data.data);
          }
        })
        .catch(error => {
          console.log("ERROR ON setContent function", error);
          content = null;
        })
        .finally(() => {
          console.log("ALL FINISH");
        });
    },
    async setBlocksEN({ commit }) {
      // console.log("node", node);
      contentAPI
        .getBlocks({
          type: "fb_block_section",
          lang: "en"
        })
        .then(data => {
          if (data.data.data) {
            commit("SET_BLOCKS_EN", data.data.data);
          }
        })
        .catch(error => {
          console.log("ERROR ON setContent function", error);
          content = null;
        })
        .finally(() => {
          console.log("ALL FINISH");
        });
    },
    async setContent({ commit }, node) {
      // console.log("node", node);
      await content
        .getContent(node)
        .then(data => {
          if (data.data.data) {
            if (node.lang === "en") {
              commit("SET_CONTENT_EN", data.data.data);
            } else {
              commit("SET_CONTENT_ES", data.data.data);
            }
          }
        })
        .catch(() => {})
        .finally(() => {});
    },
    async setGeneralContent({ commit }) {
      const content = await api.getGeneralContent();
      if (content.data) {
        commit("setGeneralContent", content.data);
      }
    },
    async setGeneralContentDraft({ commit }) {
      const content = await api.getGeneralContentDraft();
      if (content) {
        commit("setGeneralContent", content);
      }
    },
    async setMenu({ commit }) {
      await menu()
        .then(data => {
          const languages = data.map(item => item.data[0]);
          const enContent = languages.filter(item => item.langcode === "en");
          const esContent = languages.filter(item => item.langcode === "es");
          const menuContent = {
            en: enContent[0],
            es: esContent[0]
          };
          commit("SET_MENU", menuContent);
        })
        .catch(() => {
          commit("SET_MENU", null);
        })
        .finally(() => {});
    },
    async setUrlMap({ commit }) {
      await urlMap()
        .then(data => {
          const enContent = data.data.filter(item => item.lang === "en");
          const esContent = data.data.filter(item => item.lang === "es");
          const content = {
            en: enContent,
            es: esContent
          };
          commit("SET_URL_MAP", content);
        })
        .catch(() => {
          commit("SET_URL_MAP", null);
        })
        .finally(() => {
          // console.log("Finish to load URL MAP");
        });
    },
    async setAllContent({ dispatch }) {
      await Promise.all([
        dispatch("setGeneralContent", {
          type: "basic_page",
          id: null,
          lang: "es"
        }),
        dispatch("setGeneralContent", {
          type: "basic_page",
          id: null,
          lang: "en"
        })
      ]);
    },
    async setBaseData({ dispatch }) {
      await Promise.all([
        dispatch("setMenu"),
        dispatch("setMessages"),
        dispatch("setBlocksES"),
        dispatch("setBlocksEN")
      ]);
    }
  },
  getters: {
    getMessage: state => template => {
      if (!state.messagesContent) {
        return null;
      }
      if (Object.keys(state.messagesContent).length === 0) {
        return null;
      }

      return state.messagesContent.filter(
        ({ field_custom_template }) => field_custom_template === template
      );
    },
    getBlockContent: state => node => {
      let content = null;
      console.log("node", node);
      if(node.lang === 'es') {
        if (state.blocksES) {
          content = state.blocksES;
        }

      } else {
        if (state.blocksEN) {
          content = state.blocksEN;
        }
      }

      if (Object.keys(content).length === 0) {
        return null;
      }

      return content.filter(
          ({ drupal_internal__nid }) => drupal_internal__nid === node.nid
      );
    },
    urlMap: state => state.urlMap,
    getUrlTrans: state => pathData => {
      let data = state.urlMap.es;
      if (pathData.lang === "en") {
        data = state.urlMap.en;
      }

      return data.filter(({ uuid }) => uuid === pathData.uuid);
    },
    getUrlData: state => pathData => {
      let data = state.urlMap.es;
      const path = pathData.path;

      if (pathData.lang === "en") {
        data = state.urlMap.en;
      }

      return data.filter(({ uri_decoupled }) => uri_decoupled === path);
    },
    getUrlDataByUUID: state => pathData => {
      const uuidOriginal = pathData.uuid;
      const lang = pathData.lang;
      let data = state.urlMap.es;
      if (lang === "en") {
        data = state.urlMap.en;
      }

      return data.filter(({ uuid }) => uuid === uuidOriginal);
    },
    getContentES: state => uuid => {
      if (!state.contentES) {
        return null;
      }
      if (state.contentES.length === 0) {
        return null;
      }
      return state.contentES.filter(({ id }) => id === uuid);
    },
    getContentEN: state => uuid => {
      if (!state.contentEN) {
        return null;
      }
      if (state.contentEN.length === 0) {
        return null;
      }
      return state.contentEN.filter(({ id }) => id === uuid);
    },
    getMessageES: state => template => {
      if (!state.messagesContent) {
        return null;
      }
      if (Object.keys(state.messagesContent).length === 0) {
        return null;
      }
      if (!Array.isArray(state.messagesContent.es)) {
        return null;
      }

      if (state.messagesContent.es.length === 0) {
        return null;
      }
      return state.messagesContent.es.filter(
        ({ field_custom_template }) => field_custom_template === template
      );
    },
    getMessageEN: state => template => {
      if (!state.messagesContent) {
        return null;
      }
      if (Object.keys(state.messagesContent).length === 0) {
        return null;
      }
      if (!Array.isArray(state.messagesContent.en)) {
        return null;
      }
      if (state.messagesContent.en.length === 0) {
        return null;
      }
      return state.messagesContent.en.filter(
        ({ field_custom_template }) => field_custom_template === template
      );
    }
  }
});
