<template>
  <div v-if="sectionData" class="page--header ph--style-2">
    <div class="wrapper">
      <div class="flex-row">
        <div class="col-lg-6 col-xs-12">
          <div class="ph-s2-content">
            <h1
              v-if="sectionData.subtitle"
              v-html="sectionData.subtitle"
              class="display--4"
            ></h1>
            <h2
              v-if="sectionData.title"
              v-html="sectionData.title"
              class="display--2"
            ></h2>
            <div
              v-if="sectionData.description"
              v-html="sectionData.description"
            ></div>
          </div>
        </div>

        <div class="col-lg-6 col-xs-12" v-if="sectionData.bg">
          <div class="ph-s2-image">
            <img
              :src="sectionData.bg.url"
              alt="About Us"
            />
            <span class="description" v-if="sectionData.image_text" v-html="sectionData.image_text" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AboutUsHeroBanner',
  props: {
    sectionData: {
      type: Object,
      required: true
    }
  },
  methods: {
    getBackground(image) {
      const imageData = image

      const fullUrl = this.$store.state.hDrupalBaseUrl + '/' + imageData

      return fullUrl.replace('/public://', '/sites/default/files/')
    }
  }
}
</script>

<style scoped></style>
