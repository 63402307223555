<template>
  <div v-if="sectionData" class="wrapper">
    <h2
      v-if="sectionData.title"
      v-html="sectionData.title"
      class="display--1 display--inner no-break"
    ></h2>
    <div class="pb--s2-cta">
      <div class="flex-row has--padding v-center">
        <div
          v-if="sectionData.description"
          v-html="sectionData.description"
          class="col-xl-7 col-lg-6 col-xs-12"
        ></div>
        <div
          v-if="sectionData.items"
          class="col-xl-5 col-lg-6 col-xs-12 hide-on-1024"
        >
          <router-link
            v-if="sectionData.items[0]"
            :to="sectionData.items[0].link.uri"
            role="button"
            class="primary-outline-button button button-block"
          >
            {{ sectionData.items[0].title }}
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PerksBenefitsTitleContentList",
  props: {
    sectionData: {
      type: Object,
      required: true
    }
  },
  methods: {}
};
</script>

<style scoped></style>
