<template>
  <nav class="language-nav">
    <ul class="links navbar-nav nav">
      <li
        v-for="(lang, index) in supportedLanguages"
        :key="index + 222"
        :class="[{ 'is-active': isCurrentLanguage(lang) }, lang]"
      >
        <a
          href=""
          :class="{ 'is-selected': isCurrentLanguage(lang) }"
          class="language-link"
          @click="changeLanguage"
          :data-language="lang"
          data-test="testing"
          ><span :data-language="lang">{{ langLabel(lang) }}</span></a
        >
      </li>
    </ul>
  </nav>
  <!--  <div class="switch-box">-->
  <!--    <a-->
  <!--      href="#"-->
  <!--      class="select-lang"-->
  <!--      @click="changeLanguage"-->
  <!--      :data-language="lang"-->
  <!--      :class="{ 'is-selected': isCurrentLanguage(lang) }"-->
  <!--      v-for="(lang, index) in supportedLanguages"-->
  <!--      :key="index"-->
  <!--    >-->
  <!--      {{ langLabel(lang) }}-->
  <!--    </a>-->
  <!--  </div>-->
</template>

<script>
import { Trans } from "@/plugins/translation";
export default {
  name: "LanguageSwitch",
  computed: {
    supportedLanguages() {
      return Trans.supportedLanguages;
    },
    currentLanguage() {
      return Trans.currentLanguage;
    }
  },
  methods: {
    langLabel(lang) {
      if (lang === "en") {
        return "English";
      }
      return "Español";
    },
    setLanguage(trans){
      if(trans === "en"){
        return "es";
      }

      return "en";
    },
    changeLanguage(e) {
      if(this.$route.name === 'Cookies'){
        const currentLang = this.$i18n.locale;
        let path = '/en/cookies';
        if(currentLang === 'en'){
          path = '/es/cookies';
        }
        e.preventDefault();
        window.location.href = path;
        return true;
      }
      const lang = e.target.dataset.language;
      const to = this.$router.resolve({ params: { lang } });
      // console.log("lang to", lang, to, e.target);
      e.preventDefault();
      return Trans.changeLanguage(lang).then(() => {
        // let prevLang = "es";
        // if (lang === "es") {
        //   prevLang = "en";
        // }
        // const prevPath = "/" + prevLang + "/" + to.location.params.general_path;
        // const prevUrlData = this.$store.getters.getUrlData({
        //   lang: prevLang,
        //   path: prevPath
        // });
        // if (prevUrlData[0]) {
        //   const newUrlData = this.$store.getters.getUrlDataByUUID({
        //     uuid: prevUrlData[0].uuid,
        //     lang: lang
        //   });
        //   if (newUrlData[0]) {
        //     this.$router.push({ path: newUrlData[0].uri });
        //   } else {
        //     this.$router.push(to.location);
        //   }
        // }
        // console.log(
        //   "********************* SWITCH DEFAULT ROUTE - LanguageSwitch ***********************"
        // );
        this.$router.push(to.location);
        // console.log("************ LANGUAGE prevLang", prevLang, prevPath, prevUrlData, newUrlData);
      });
    },
    isCurrentLanguage(lang) {
      return lang === this.currentLanguage;
    }
  }
};
</script>

<style scoped lang="scss">
  .language-nav{
    .is-active{
      display: none;
    }
    .links{
      margin: 0;
      padding: 0;
    }
    li a{
      color: #fff;
    }
  }
.select-lang {
  &.is-selected {
    display: none;
  }
}
.switch-box {
  display: inline-block;
  padding-right: 10px;
}
</style>
