import $http from "@/services/axios";
import { SUPPORTED_LANGUAGES } from "@/utils/i18Defaults";

async function menuData(lang) {
  const params = {
    jsonapi_include: 1,
    "fields[paragraph--menu]":
      "langcode,field_pg_menu_link,field_pg_menu_link_content,field_pg_menu_link_sub"
  };

  return await $http
    .get(lang + "/" + `${process.env.VUE_APP_HDRUPAL_MENU_URI}`, {
      params
    })
    .then(({ data }) => {
      return data;
    });
}

async function menu() {
  return await Promise.all(
    SUPPORTED_LANGUAGES.map(lang => {
      return menuData(lang);
    })
  );
}

export { menu };
