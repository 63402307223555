<!--gallery_subtitle: contentData.field_gallery_subtitle,-->
<!--gallery_title: contentData.field_gallery_title,-->
<!--gallery_description: contentData.field_gallery_description,-->
<!--gallery_item: contentData.field_gallery_item,-->
<!--gallery_label_link: contentData.field_gallery_label_link-->

<template>
  <splide v-if="mediaList" class="splide splide-modal-open" :options="options">
    <splide-slide v-for="(item, key) in mediaList" :key="key" class="cgbox">
      <div class="cgbox-inner">
        <div class="cgbox-image">
          <div v-if="item.type === 'image'">
            <img :src="item.file.url" />
          </div>
          <div v-if="item.type === 'youtube'" class="cgbox-video-container">
            <iframe :src="item.file.uri" width="350" height="200" />
          </div>
          <div v-if="item.type === 'video'" class="cgbox-video-container">
            <video controls width="100%">
              <source :src="item.file.url" type="video/mp4" />
            </video>
          </div>
        </div>
        <!--        <div class="cgbox-image">-->
        <!--          <img :src="item.file.url" />-->
        <!--        </div>-->
      </div>
    </splide-slide>

    <!-- Slider Controls -->
    <template v-slot:controls>
      <div class="splide__arrows custom__arrows">
        <button class="splide__arrows splide__arrow--prev">
          <svg
            style="transform: scale(1,-1)"
            xmlns="http://www.w3.org/2000/svg"
            width="54.4"
            height="19.2"
            viewBox="0 0 54.4 19.2"
          >
            <path
              d="M53.437,8.6H3.309l7.124-6.99a.932.932,0,0,0,0-1.336.976.976,0,0,0-1.362,0L.409,8.777A.937.937,0,0,0,0,9.547s0,.009,0,.014,0,.02,0,.03a.922.922,0,0,0,.052.264.9.9,0,0,0,.069.151.947.947,0,0,0,.082.121.885.885,0,0,0,.074.109l8.853,8.687a.976.976,0,0,0,1.362,0,.932.932,0,0,0,0-1.336l-7.232-7.1H53.437a.945.945,0,1,0,0-1.889Z"
              fill="#028953"
            />
          </svg>
        </button>
        <button class="splide__arrows splide__arrow--next">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="54.4"
            height="19.2"
            viewBox="0 0 54.4 19.2"
          >
            <path
              d="M-53.437,8.6H-3.309l-7.124-6.99a.932.932,0,0,1,0-1.336.976.976,0,0,1,1.362,0l8.663,8.5A.937.937,0,0,1,0,9.547s0,.009,0,.014,0,.02,0,.03a.922.922,0,0,1-.052.264.9.9,0,0,1-.069.151.947.947,0,0,1-.082.121.885.885,0,0,1-.074.109l-8.853,8.687a.969.969,0,0,1-.681.277.969.969,0,0,1-.681-.277.932.932,0,0,1,0-1.336l7.232-7.1H-53.437a.954.954,0,0,1-.963-.945A.954.954,0,0,1-53.437,8.6Z"
              transform="translate(54.4)"
              fill="#028953"
            />
          </svg>
        </button>
      </div>
      <!--                <div class="splide__progress">-->
      <!--                    <div class="splide__progress__bar">-->
      <!--                    </div>-->
      <!--                </div>-->
    </template>
  </splide>
</template>

<script>
import { Splide, SplideSlide } from "@splidejs/vue-splide";
export default {
  name: "SplideModalMedia",
  components: {
    Splide,
    SplideSlide
  },
  props: {
    mediaList: {
      required: true
    }
  },
  data() {
    return {
      options: {
        type: "loop",
        perPage: 1,
        gap: "1rem",
        autoplay: false,
        pauseOnHover: false,
        width: "1024px",
        heightRatio: 0.5,
        focus: "center",
        cover: true,
        pagination: true
      },
      player: null
    };
  },
  methods: {
    getBackground(image) {
      const imageData = image;
      // .replace('public://', '')
      const fullUrl = this.$store.state.hDrupalBaseUrl + "/" + imageData;

      return fullUrl.replace("/public://", "/sites/default/files/");
    },
    fixUrl(uri) {
      const newUri = uri.replace("internal:", "");
      return newUri;
    }
  }
};
</script>

<style lang="scss">
.cgbox-video-container {
  display: block;
  width: 65%;
  margin-left: auto;
  margin-right: auto;
  iframe {
    width: 100%!important;
    height: 345px;
    @media(max-width: 400px) {
      height: 200px;
    }
  }
}
.modal-splide-wrapper {
  @media (max-width: 760px) {
    width: 290px;
    margin: 0 auto;
  }
  @media (max-width: 330px) {
    width: 100%;
  }
}
.splide-modal-open {
  margin-left: auto;
  margin-right: auto;
  max-height: 400px;
  @media (max-width: 850px) {
    max-height: 300px !important;
  }
  /*@media(max-width: 768px){*/
  /*    max-height: 300px!important;*/
  /*}*/
  /*@media(max-width: 330px){*/
  /*    max-height: 300px!important;*/
  /*}*/
  .splide__track {
    text-align: center;
    position: unset;
  }
  .cgbox-image {
    img {
      max-height: 340px;
      @media (max-width: 1024px) {
        max-height: 320px;
      }
      @media (max-width: 850px) {
        height: 185px;
      }
      /*@media(max-width: 330px){*/
      /*    max-height: 150px;*/
      /*}*/
    }
  }
  .splide__slide {
    position: unset;
    height: 386px !important;
    /*@media(max-width: 1024px){*/
    /*    height: auto!important;*/
    /*}*/
    @media (max-width: 850px) {
      height: 275px !important;
    }
    @media (max-width: 600px) {
      height: auto !important;
    }
    .cgbox-text {
      position: relative;
      bottom: -4px !important;
      width: 100%;
      text-align: center;
      font-weight: 400;
      color: #366a5a;
      @media (max-width: 1024px) {
        bottom: 0;
      }
    }
  }
  .splide__arrows.custom__arrows {
    width: 100% !important;
    left: auto !important;
    right: 40px !important;
    display: inline-block !important;
    bottom: 5px !important;
    @media (max-width: 1024px) {
      text-align: center !important;
      padding: 0 !important;
      right: 0 !important;
      left: 0 !important;
      width: 100% !important;
    }
    @media (max-width: 760px) {
      padding-top: 10px;
      margin-top: 10px;
    }
    @media (max-width: 400px) {
      bottom: -50px;
    }
  }
  .splide__pagination {
    bottom: 20px !important;
    position: absolute !important;
    left: 40px;
    @media (max-width: 1024px) {
      display: none !important;
    }
  }
}
</style>
