<template>
  <section v-if="isReady" class="playeros-container">
    <div class="wrapper">
      <div
        v-for="(playero, index) in content"
        :key="index"
        class="team-member-container"
      >
        <div :class="[index % 2 === 0 ? '' : 'reverse']" class="row">
          <div
            v-if="playero.field_image.uri"
            class="col-lg-4 col-xs-12 tt--col"
          >
            <div class="member-foto">
              <img
                :src="helpers.getFullImagePath(playero.field_image.uri.url)"
                alt=""
              />
            </div>
          </div>
          <div class="col-lg-8 col-xs-12 tt--col">
            <div class="member-detail">
              <h3
                v-if="playero.field_header_title"
                v-html="helpers.setTextAreaField(playero.field_header_title)"
                class="member-name"
              ></h3>
              <div
                v-if="playero.body"
                v-html="helpers.setTextAreaField(playero.body)"
                class="member-info"
              ></div>
            </div>
          </div>
        </div>
        <div class="row" v-if="playero.field_gallery">
          <div class="col-lg-12 col-xs-12 tt--col gallery-container">
            <div
              v-for="(gItem, gIndex) in playero.field_gallery"
              :key="gIndex"
              class="image-gallery"
            >
              <div class="image-item">
                <a
                    href="javascript:void(0)"
                    @click="showModal(index, gIndex)"
                >
                  <img
                    :src="helpers.getFullImagePath(gItem.field_pg_image.uri.url)"
                    alt=""
                  />
                </a>
              </div>
              <div class="content-description">
                <h4
                  v-if="gItem.field_pg_title"
                  v-html="gItem.field_pg_title"
                ></h4>
                <div
                  v-if="gItem.field_pg_content"
                  v-html="helpers.setTextAreaField(gItem.field_pg_content)"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="gallery-list">
        <modal name="galleryModal" height="auto">
          <div class="modal-dialog-galleries">
            <div class="modal-content-wrapper">
              <div class="modal-header">
                <button
                  type="button"
                  class="close"
                  @click="$modal.hide('galleryModal')"
                >
                  X
                </button>
              </div>
              <div class="modal-body">
                <div class="modal-content">
                  <img :src="helpers.getFullImagePath(content[parentKey].field_gallery[currentGalleryKey].field_pg_image.uri.url)" alt="" />
                </div>
              </div>
            </div>
          </div>
        </modal>
      </div>
    </div>
  </section>
</template>

<script>
import * as contentAPI from "@/api/content";
export default {
  name: "ArtSeriesPlayeros",
  data() {
    return {
      default: {
        title: "Playeros",
        type: "fb_block_section",
        template: "playeros"
      },
      isReady: false,
      templateName: "playeros",
      language: this.$i18n.locale,
      content: null,
      helpers: this.$helperList,
      parentKey: 0,
      currentGalleryKey: 0,
    };
  },
  methods: {
    showModal (parent, key) {
      this.$set(this, 'parentKey', parent)
      this.$set(this, 'currentGalleryKey', key)
      this.$modal.show('galleryModal');
    },
    hideModal (parent, key) {
      this.$set(this, 'parentKey', parent)
      this.$set(this, 'currentGalleryIndex', key)
      this.$modal.hide('galleryModal');
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    updateLanguage() {
      this.language = this.$i18n.locale;
      this.headerLink = "/es";
      if (this.language === "en") {
        this.headerLink = "/en";
      }
      this.parentSite = "https://www.1firstbank.com/pr/" + this.$i18n.locale;
    },
    getContent() {
      // const helpers = this.helpers;
      this.isReady = false;
      let content = null;
      contentAPI
        .getContent({
          type: this.default.type,
          uuid: null,
          template: this.default.template,
          lang: this.language
        })
        .then(data => {
          content = data.data.data;
          this.content = content;
        })
        .catch(error => {
          console.log("ERROR ON setContent function", error);
          content = null;
        })
        .finally(() => {
          if (content) {
            this.isReady = true;
          }
          // this.content = content;
          // this.isReady = true;
        });
    }
  },
  watch: {
    $route() {
      this.language = this.$i18n.locale;
      this.getContent();
      this.updateLanguage();
    }
  },
  created() {
    this.getContent();
    this.updateLanguage();
  }
};
</script>

<style scoped lang="scss"></style>
