var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{staticClass:"site-navigation"},[(_vm.sectionData)?_c('ul',_vm._l((_vm.sectionData.items),function(item,index){return _c('li',{key:item.id,class:[
          index === 0 ? 'first' : '',
          item.field_pg_menu_link_sub[0] ? 'nav-dropdown' : '',
          index + 1 === _vm.sectionData.items.length ? 'last' : '',
          _vm.isExpand(index) ? 'expand ' : '' ],on:{"mouseleave":function($event){return _vm.updateDropdownStatus(3000)}}},[(item.field_pg_menu_link_sub[0])?_c('router-link',{staticClass:"dropdown-toggle",staticStyle:{"opacity":"1","transform":"translate(0px)"},attrs:{"to":"","role":"button","aria-haspopup":"true","aria-expanded":"false"},on:{"mouseleave":function($event){return _vm.updateDropdownStatus(3000)}},nativeOn:{"click":function($event){$event.preventDefault();return _vm.updateDropdownStatus(index)}}},[_vm._v(" "+_vm._s(item.field_pg_menu_link.title)+" "),_c('i',{staticClass:"fa fa-chevron-down",attrs:{"aria-hidden":"true"}})]):_c('router-link',{attrs:{"to":_vm.helpers.getMenuUrl(item.field_pg_menu_link.uri)},nativeOn:{"click":function($event){_vm.checkMenu(_vm.helpers.getMenuUrl(item.field_pg_menu_link.uri))}}},[_vm._v(" "+_vm._s(item.field_pg_menu_link.title)+" ")]),(item.field_pg_menu_link_sub[0])?_c('div',{staticClass:"dropdown-menu",attrs:{"aria-labelledby":"navbarDropdown"}},_vm._l((item.field_pg_menu_link_sub),function(subItem,indexSub){return _c('span',{key:indexSub + 1500},[(!_vm.helpers.isExternal(_vm.helpers.getMenuUrl(subItem.uri)))?_c('router-link',{staticClass:"menu-link",class:[
              index === 0 ? 'first' : '',
              index + 1 === item.field_pg_menu_link_sub.length ? 'last' : '',
              index >=0 ? 'service-'+(indexSub+1) : ''
            ],attrs:{"to":_vm.helpers.getMenuUrl(subItem.uri)},nativeOn:{"click":function($event){_vm.checkMenu(_vm.helpers.getMenuUrl(subItem.uri))}}},[_vm._v(" "+_vm._s(subItem.title)+" ")]):_c('a',{staticClass:"menu-link",class:[
              index === 0 ? 'first' : '',
              index + 1 === item.field_pg_menu_link_sub.length ? 'last' : '',
              index >=0 ? 'service-'+(indexSub+1) : ''
            ],attrs:{"href":_vm.helpers.getMenuUrl(subItem.uri)}},[_vm._v(" "+_vm._s(subItem.title)+" ")])],1)}),0):_vm._e()],1)}),0):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }