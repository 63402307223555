var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('footer',{staticClass:"site-footer"},[_c('div',{staticClass:"footer-inner"},[_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"flex-row"},[_c('div',{staticClass:"col-lg-4 col-md-12 col-xs-12"},[_c('div',{staticClass:"footer-logo"},[_c('router-link',{attrs:{"to":_vm.headerLink},nativeOn:{"click":function($event){return _vm.scrollToTop.apply(null, arguments)}}},[_c('img',{attrs:{"src":require("@/assets/images/logo.svg"),"alt":"FirstBank Platinum"}})])],1)]),_c('div',{staticClass:"col-lg-6 col-md-12 col-xs-12"},[_c('div',{staticClass:"flex-row footer-menus"},[_c('div',{staticClass:"col-lg-6 col-md-12 col-xs-12"},[(_vm.menuIsReady)?_c('div',{staticClass:"footer-section"},[_c('h3',{staticClass:"fs-title"},[_vm._v(_vm._s(_vm.navigation.field_display_title))]),_c('ul',{staticClass:"vertical-list hidden-md-and-down"},_vm._l((_vm.navigation.items),function(item){return _c('li',{key:item.id},[(
                        !_vm.helpers.isExternal(
                          _vm.helpers.getMenuUrl(item.field_pg_menu_link.uri)
                        )
                      )?_c('router-link',{attrs:{"to":_vm.helpers.getMenuUrl(item.field_pg_menu_link.uri)},nativeOn:{"click":function($event){_vm.helpers.checkMenuFooter(
                          _vm.helpers.getMenuUrl(
                            item.field_pg_menu_link.uri,
                            _vm.language
                          )
                        )}}},[_vm._v(" "+_vm._s(item.field_pg_menu_link.title)+" ")]):_c('a',{attrs:{"href":_vm.helpers.getMenuUrl(item.field_pg_menu_link.uri)}},[_vm._v(" "+_vm._s(item.field_pg_menu_link.title)+" ")])],1)}),0),_c('ul',{staticClass:"vertical-list vl-menu vl-menu-0 col-md-6 col-xs-8 vl-row hidden-lg-and-up"},_vm._l((_vm.navigation.items.slice(0,4)),function(item){return _c('li',{key:item.id},[(
                        !_vm.helpers.isExternal(
                          _vm.helpers.getMenuUrl(item.field_pg_menu_link.uri)
                        )
                      )?_c('router-link',{attrs:{"to":_vm.helpers.getMenuUrl(item.field_pg_menu_link.uri)},nativeOn:{"click":function($event){_vm.helpers.checkMenuFooter(
                          _vm.helpers.getMenuUrl(
                            item.field_pg_menu_link.uri,
                            _vm.language
                          )
                        )}}},[_vm._v(" "+_vm._s(item.field_pg_menu_link.title)+" ")]):_c('a',{attrs:{"href":_vm.helpers.getMenuUrl(item.field_pg_menu_link.uri)}},[_vm._v(" "+_vm._s(item.field_pg_menu_link.title)+" ")])],1)}),0),_c('ul',{staticClass:"vertical-list vl-menu vl-menu-1 col-md-6 col-xs-4 vl-row hidden-lg-and-up"},_vm._l((_vm.navigation.items.slice(4, 10)),function(item){return _c('li',{key:item.id},[(
                        !_vm.helpers.isExternal(
                          _vm.helpers.getMenuUrl(item.field_pg_menu_link.uri)
                        )
                      )?_c('router-link',{attrs:{"to":_vm.helpers.getMenuUrl(item.field_pg_menu_link.uri)},nativeOn:{"click":function($event){_vm.helpers.checkMenuFooter(
                          _vm.helpers.getMenuUrl(
                            item.field_pg_menu_link.uri,
                            _vm.language
                          )
                        )}}},[_vm._v(" "+_vm._s(item.field_pg_menu_link.title)+" ")]):_c('a',{attrs:{"href":_vm.helpers.getMenuUrl(item.field_pg_menu_link.uri)}},[_vm._v(" "+_vm._s(item.field_pg_menu_link.title)+" ")])],1)}),0)]):_vm._e()]),(_vm.addressIsReady)?_c('div',{staticClass:"col-lg-6 address-footer col-md-12 col-xs-12"},_vm._l((_vm.address),function(item,index){return _c('div',{key:item.id,staticClass:"footer-section footer-address-section address-mb",class:[
                  index === 0 ? 'first' : '',
                  index + 1 === _vm.address.length ? 'last' : '',
                  index >= 0 ? 'item-' + index : ''
                ]},[_c('h3',{staticClass:"fs-title"},[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"footer-address",domProps:{"innerHTML":_vm._s(item.content)}})])}),0):_vm._e()])]),_c('div',{staticClass:"col-lg-2 col-md-8 col-xs-12 hidden-md-and-down"},[_c('div',{staticClass:"footer-logo footer-logo-icon"},[_c('a',{staticClass:"router-link-active",staticStyle:{"opacity":"1","transform":"translate(0px)"},attrs:{"href":_vm.parentSite}},[_c('img',{attrs:{"data-v-be203b90":"","src":"/img/fblogo.b63b7074.svg","alt":"FirstBank Platinum"}})])])])])])]),(_vm.isReady)?_c('div',{staticClass:"copyrights"},[_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"flex-row"},[(_vm.content.body)?_c('div',{staticClass:"col-lg-9 col-xs-12 text-section footer-wp",domProps:{"innerHTML":_vm._s(_vm.content.body.value)}}):_vm._e(),_vm._m(0)])])]):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-lg-3 col-xs-12 icons-section footer-wp"},[_c('div',{staticClass:"icon-container"},[_c('img',{attrs:{"src":require("../assets/images/icons/logofdic@2x_0.png")}}),_c('img',{attrs:{"src":require("../assets/images/icons/logo1@2x_0.png")}}),_c('img',{attrs:{"src":require("../assets/images/icons/logo2@2x_0.png")}})])])}]

export { render, staticRenderFns }