<template>
  <div id="app">
    <SiteMessages />
    <div class="body-container">
      <c-header></c-header>
      <div class="content-box-wrapper">
        <router-view />
      </div>
      <c-footer></c-footer>
    </div>
    <CCookieModal />
  </div>
</template>

<script>
import SiteMessages from "@/components/AlertMessage"
import CHeader from "@/components/Header.vue";
import CFooter from "@/components/Footer.vue";
import CCookieModal from "@/components/cookieModal";
import { gsap } from "gsap";
export default {
  components: {
    CCookieModal,
    CHeader,
    CFooter,
    SiteMessages,
  },
  data() {
    return {
      urlModal: " ",
    };
  },
  methods: {
    animate() {
      const tl = gsap.timeline();
      tl.from(
        ".da-input-component, .da-select-wrapper__arrow, .animated, button, p, a, h2, li, .da-list-title, .da-divider",
        {
          duration: 0.5,
          ease: "back",
          y: 8,
          opacity: 0,
          stagger: 0.1
        },
        "<.1"
      );
    },
  },
  mounted() {
    this.animate();
    // const elementClicked = document.querySelector(".nav-dropdown");
    // const elementYouWantToShow = document.querySelector(".nav-dropdown");
    //
    //     elementClicked.addEventListener("click", ()=>{
    //       elementYouWantToShow.classList.toggle("expand");
    //
    //     });
    //     elementYouWantToShow.addEventListener("mouseleave", ()=>{
    //
    //           elementYouWantToShow.classList.remove("expand");
    //
    //     });
    //     document.querySelector('.hamburger').onclick = function (e) {
    //     var body = document.querySelector('body');
    //     var nav = document.querySelector('.hamburger');
    //     body.classList.toggle('open--nav');
    //     nav.classList.toggle('is-active');
    //     e.preventDefault();
    //     //var elem = document.querySelector('.ostitle');
    //
    //     /*if (elem) {
    //       elem.onclick = function (e) {
    //         var menu = document.querySelector('.our-services');
    //         menu.classList.toggle('is--active');
    //         e.preventDefault();
    //       }
    //     }*/
    // }
  }
};
</script>

<style lang="scss">
.content-box-wrapper{
  min-height: 75vh;
}

  /*Remover luego */
  .modal-dialog-speed-bump{
    padding: 2% 5% 3%;
    overflow: hidden;
    width: 100%;
    .modal-header{
      padding: 0;
      border: none;
      display: block;
      width: 100%;
      float: right;
      .close{
        float: right;
        font-size: 21px;
        font-weight: 700;
        line-height: 1;
        color: #000;
        text-shadow: 0 1px 0 #fff;
        filter: alpha(opacity=20);
        opacity: .2;
        &:hover{
          color: #000;
          text-decoration: none;
          cursor: pointer;
          filter: alpha(opacity=50);
          opacity: .5;
        }
      }
    }

    .modal-body{
      margin: 0;
      padding: 0;
      width: 100%;
      clear: both;
      float: left;
      .body-content{
        padding: 0 0 10px;
      }
      p{
        font-family: Arial, sans-serif;
        font-size: 16px;
        line-height: 1.5;
      }


    }
    .body-footer{
      margin: 0;
      padding: 0;
      text-align: right;
      .btn{
        font-family: Arial, sans-serif;
        font-weight: normal;
        border-radius: 2px;
        font-size: 20px;
        line-height: 1.25;
        display: inline-block;
        text-align: center;
        border: 0;
        user-select: none;
        vertical-align: middle;
        cursor: pointer;
        padding: 10px 29px 5px 29px;
        border: 1px solid transparent;
        outline: 0;
        text-decoration: none;
        transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
        background-color: #038459;
        color: #FFFFFF;
        box-shadow: none;
        display: inline-block;
        &:hover{
          -webkit-border-radius: 100px;
          -moz-border-radius: 100px;
          border-radius: 100px;
        }
      }
    }
  }
</style>
