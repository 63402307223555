<template>
  <footer class="site-footer">
    <div class="footer-inner">
      <div class="wrapper">
        <div class="flex-row">
          <div class="col-lg-4 col-md-12 col-xs-12">
            <div class="footer-logo">
              <router-link :to="headerLink" @click.native="scrollToTop">
                <img src="@/assets/images/logo.svg" alt="FirstBank Platinum" />
              </router-link>
            </div>
          </div>
          <div class="col-lg-6 col-md-12 col-xs-12">
            <div class="flex-row footer-menus">
              <div class="col-lg-6 col-md-12 col-xs-12">
                <div class="footer-section" v-if="menuIsReady">
                  <h3 class="fs-title">{{ navigation.field_display_title }}</h3>
                  <ul class="vertical-list hidden-md-and-down">
                    <li :key="item.id" v-for="item in navigation.items">
                      <router-link
                        :to="helpers.getMenuUrl(item.field_pg_menu_link.uri)"
                        @click.native="
                          helpers.checkMenuFooter(
                            helpers.getMenuUrl(
                              item.field_pg_menu_link.uri,
                              language
                            )
                          )
                        "
                        v-if="
                          !helpers.isExternal(
                            helpers.getMenuUrl(item.field_pg_menu_link.uri)
                          )
                        "
                      >
                        {{ item.field_pg_menu_link.title }}
                      </router-link>
                      <a
                        :href="helpers.getMenuUrl(item.field_pg_menu_link.uri)"
                        v-else
                      >
                        {{ item.field_pg_menu_link.title }}
                      </a>
                    </li>
                  </ul>
                  <ul class="vertical-list vl-menu vl-menu-0 col-md-6 col-xs-8 vl-row hidden-lg-and-up">
                    <li
                      :key="item.id"
                      v-for="item in navigation.items.slice(0,4)"
                    >
                      <router-link
                        :to="helpers.getMenuUrl(item.field_pg_menu_link.uri)"
                        @click.native="
                          helpers.checkMenuFooter(
                            helpers.getMenuUrl(
                              item.field_pg_menu_link.uri,
                              language
                            )
                          )
                        "
                        v-if="
                          !helpers.isExternal(
                            helpers.getMenuUrl(item.field_pg_menu_link.uri)
                          )
                        "
                      >
                        {{ item.field_pg_menu_link.title }}
                      </router-link>
                      <a
                        :href="helpers.getMenuUrl(item.field_pg_menu_link.uri)"
                        v-else
                      >
                        {{ item.field_pg_menu_link.title }}
                      </a>
                    </li>
                  </ul>
                  <ul class="vertical-list vl-menu vl-menu-1 col-md-6 col-xs-4 vl-row hidden-lg-and-up">
                    <li
                      :key="item.id"
                      v-for="item in navigation.items.slice(4, 10)"
                    >
                      <router-link
                        :to="helpers.getMenuUrl(item.field_pg_menu_link.uri)"
                        @click.native="
                          helpers.checkMenuFooter(
                            helpers.getMenuUrl(
                              item.field_pg_menu_link.uri,
                              language
                            )
                          )
                        "
                        v-if="
                          !helpers.isExternal(
                            helpers.getMenuUrl(item.field_pg_menu_link.uri)
                          )
                        "
                      >
                        {{ item.field_pg_menu_link.title }}
                      </router-link>
                      <a
                        :href="helpers.getMenuUrl(item.field_pg_menu_link.uri)"
                        v-else
                      >
                        {{ item.field_pg_menu_link.title }}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div
                class="col-lg-6 address-footer col-md-12 col-xs-12"
                v-if="addressIsReady"
              >
                <div
                  class="footer-section footer-address-section address-mb"
                  :class="[
                    index === 0 ? 'first' : '',
                    index + 1 === address.length ? 'last' : '',
                    index >= 0 ? 'item-' + index : ''
                  ]"
                  v-for="(item, index) in address"
                  :key="item.id"
                >
                  <h3 class="fs-title">{{ item.title }}</h3>
                  <div class="footer-address" v-html="item.content" />
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-2 col-md-8 col-xs-12 hidden-md-and-down">
            <div class="footer-logo footer-logo-icon">
              <a
                :href="parentSite"
                class="router-link-active"
                style="opacity: 1; transform: translate(0px);"
              >
                <img
                  data-v-be203b90=""
                  src="/img/fblogo.b63b7074.svg"
                  alt="FirstBank Platinum"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="copyrights" v-if="isReady">
      <div class="wrapper">
        <div class="flex-row">
          <div
            class="col-lg-9 col-xs-12 text-section footer-wp"
            v-if="content.body"
            v-html="content.body.value"
          />
          <div class="col-lg-3 col-xs-12 icons-section footer-wp">
            <div class="icon-container">
              <img src="../assets/images/icons/logofdic@2x_0.png" />
              <img src="../assets/images/icons/logo1@2x_0.png" />
              <img src="../assets/images/icons/logo2@2x_0.png" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import * as contentAPI from "@/api/content";
export default {
  name: "Footer",
  data() {
    return {
      default: {
        uuid: "86c5402a-689d-4aef-8ac6-f5359c8dbb22",
        nid: 686,
        title: "Footer",
        type: "fb_block_section"
      },
      navigationMenu: {
        uuid: "78d51e9c-e69a-4e3c-82df-210a93dab36d",
        nid: 687,
        title: "Navigation Menu",
        type: "group_menu"
      },
      addressFooter: {
        uuid: "a53ea883-d3b6-4110-973f-03c1cc0b94b0",
        nid: 704,
        title: "Footer Address Book",
        type: "fb_block_section"
      },
      parentSite: 'https://www.1firstbank.com/pr/' + this.$i18n.locale,
      isReady: false,
      address: null,
      menuIsReady: false,
      templateName: "legal-footer",
      addressIsReady: false,
      language: this.$i18n.locale,
      headerLink: "/" + this.$i18n.locale,
      content: null,
      navigation: null,
      helpers: this.$helperList
    };
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    updateLanguage() {
      this.language = this.$i18n.locale;
      this.headerLink = "/es";
      if (this.language === "en") {
        this.headerLink = "/en";
      }
      this.parentSite= 'https://www.1firstbank.com/pr/' + this.$i18n.locale;
    },
    getMenu() {
      // const helpers = this.helpers;
      this.menuIsReady = false;
      let navigation = null;
      contentAPI
        .getContent({
          type: this.navigationMenu.type,
          uuid: this.navigationMenu.uuid,
          lang: this.language
        })
        .then(data => {
          navigation = data.data.data;
        })
        .catch(error => {
          console.log("ERROR ON setContent function", error);
          navigation = null;
        })
        .finally(() => {
          if (navigation) {
            this.menuIsReady = true;
            this.navigation = navigation;
          } else {
            this.navigation = null;
          }
        });
    },
    getLegal() {
      // const helpers = this.helpers;
      this.isReady = false;
      let content = null;
      contentAPI
        .getContent({
          type: this.default.type,
          uuid: this.default.uuid,
          lang: this.language
        })
        .then(data => {
          content = data.data.data;
          this.content = content;
        })
        .catch(error => {
          console.log("ERROR ON setContent function", error);
          content = null;
        })
        .finally(() => {
          if (content) {
            this.isReady = true;
          }
          // this.content = content;
          // this.isReady = true;
        });
    },
    getAddress() {
      this.addressIsReady = false;
      let content = null;
      contentAPI
        .getContent({
          type: this.addressFooter.type,
          uuid: this.addressFooter.uuid,
          lang: this.language
        })
        .then(data => {
          content = data.data.data;
        })
        .catch(error => {
          console.log("ERROR ON setContent function", error);
          content = null;
        })
        .finally(() => {
          if (content) {
            this.addressIsReady = true;
            this.address = this.$helperList.getAddressGroup(content);
          } else {
            this.address = null;
            this.addressIsReady = false;
          }
          // this.content = content;
          // this.isReady = true;
        });
    }
  },
  watch: {
    $route() {
      this.language = this.$i18n.locale;
      this.getLegal();
      this.updateLanguage();
      this.getMenu();
      this.getAddress();
    }
  },
  created() {
    this.getLegal();
    this.getMenu();
    this.updateLanguage();
    this.getAddress();
  }
};
</script>

<style scoped lang="scss">
.footer-address-section {
  .footer-address {
    line-height: 35px;
  }
  &.item-0 {
    margin-bottom: 44px;
  }
}
.address-footer{
  @media(max-width: 1024px){
    padding-top: 0!important;
  }
}
.footer-wp {
  @media (max-width: 1024px) {
    width: 100% !important;
    clear: both;
  }
  &.text-section {
    float: left;
  }
  &.icons-section {
    @media (max-width: 1024px) {
      margin-top: 40px;
    }
    float: right;
    .icon-container {
      float: right;
      @media (max-width: 1024px) {
        width: 100%;
        text-align: center;
      }
    }
    img {
      width: 36px;
      margin-right: 10px;
      vertical-align: middle;
    }
  }
}
</style>
