// --- CORE
import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import i18n from "@/plugins/i18n";
import AxiosAPI from "./services/axios";
import interceptors from "@/utils/interceptors";
import store from "./store";
import VueLazyLoad from "vue-lazyload";
import VModal from "vue-js-modal";
import VueScrollTo from "vue-scrollto";
import VueCookies from "vue-cookies";
import titleMixin from "./utils/titleMixin";
import VueGtag from "vue-gtag";
import helperList from "@/utils/helpers";
import "./assets/styles/styles.scss";
require("vue-image-lightbox/dist/vue-image-lightbox.min.css");

Vue.use(VueCookies);

Vue.$cookies.config("365d");
const canSetGA = Vue.$cookies.get("platinum_c_status");

let ga = true;

if (canSetGA) {
  ga = canSetGA.analytics.value;
}
if (ga) {
  Vue.use(
    VueGtag,
    {
      config: { id: "GTM-K87MWJJ" },
      appName: "Platinum",
      pageTrackerScreenviewEnabled: true
    },
    router
  );
}

Vue.use(VModal, {
  dynamicDefaults: {
    adaptive: true,
    scrollable: true
  }
});
Vue.use(VueLazyLoad);
Vue.use(VueScrollTo);
Vue.mixin(titleMixin);
Vue.config.productionTip = false;
Vue.use(require("vue-moment"));
// handle page reloads
Vue.prototype.$helperList = helperList;
Vue.prototype.$http = AxiosAPI;
Vue.prototype.$store = store;
interceptors();

store.dispatch("setUrlMap").finally(() => {
  let app;
  if (!app) {
    app = new Vue({
      el: "#app",
      i18n,
      router,
      store,
      created() {},
      render: h => h(App)
    });
  }
});
store.dispatch("setBaseData");
