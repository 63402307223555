<template>
  <section v-if="sectionData" class="hs-3">
    <div class="wrapper">
      <h2
        v-if="sectionData.title"
        v-html="sectionData.title"
        class="display--1 no-break"
      ></h2>
      <div v-if="sectionData.listContent" class="why-boxes">
        <div class="flex-row">
          <div
            v-for="item in sectionData.listContent"
            :key="item.id"
            class="col-lg-4 col-xs-12 wcol"
          >
            <div class="why-box">
              <h3
                class="wbTitle"
                v-if="item.title"
                v-html="item.title"
              ></h3>
              <!-- el div rompe el css de original verificar-->
              <div
                      class="content-box-item"
                v-if="item.description"
                v-html="item.description"
              ></div>
              <router-link
                v-if="item.link"
                :to="item.link.uri"
                class="wb-link"
              >
                {{ item.link.title }}
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "TitleContentBoxes",
  props: {
    sectionData: {
      type: Object,
      required: true
    }
  },
  methods: {}
};
</script>

<style scoped>
  .content-box-item{
    margin-bottom: 24px;
  }
</style>
