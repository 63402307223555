<template>
  <div>
    <div v-if="sectionData" class="page--header">
      <div class="wrapper">
        <div class="ph-content">
          <h1
                  v-if="sectionData.title"
                  v-html="sectionData.title"
                  class="display--2"
          ></h1>
          <div
                  v-if="sectionData.description"
                  v-html="sectionData.description"
          ></div>
        </div>
      </div>
    </div>
    <section
            v-if="sectionData.bg"

            class="banner-width-caption"
    >
      <div
              class="banner-image"
      >
        <img :src="sectionData.bg.url">
      </div>
      <div class="banner-caption">
        <div class="wrapper">
          <div
                  v-if="sectionData.image_text"
                  v-html="sectionData.image_text"
                  class="bc-title"
          ></div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'BankingBanner',
  props: {
    sectionData: {
      type: Object,
      required: true
    }
  },
  methods: {}
}
</script>

<style scoped lang="scss">
  .banner-width-caption .banner-image{
    height: auto;
    img{
      width: 100%;
      height: auto;
    }
  }
</style>
