<template>
  <nav class="site-navigation">
    <ul v-if="sectionData">
      <li
              :class="[
          index === 0 ? 'first' : '',
          item.field_pg_menu_link_sub[0] ? 'nav-dropdown' : '',
          index + 1 === sectionData.items.length ? 'last' : '',
          isExpand(index) ? 'expand ' : '',
        ]"
              :key="item.id"
              v-for="(item, index) in sectionData.items"
              @mouseleave="updateDropdownStatus(3000)"
      >
        <router-link
                class="dropdown-toggle"
                to=""
                v-if="item.field_pg_menu_link_sub[0]"
                @click.native.prevent="updateDropdownStatus(index)"
                role="button"
                aria-haspopup="true"
                aria-expanded="false"
                style="opacity: 1; transform: translate(0px);"
                @mouseleave="updateDropdownStatus(3000)"
        >
          {{ item.field_pg_menu_link.title }}
          <i aria-hidden="true" class="fa fa-chevron-down"></i>
        </router-link>

        <router-link
                v-else
                :to="helpers.getMenuUrl(item.field_pg_menu_link.uri)"
                @click.native="checkMenu(helpers.getMenuUrl(item.field_pg_menu_link.uri))"
        >
          {{ item.field_pg_menu_link.title }}
        </router-link>

        <div
                v-if="item.field_pg_menu_link_sub[0]"
                class="dropdown-menu"
                aria-labelledby="navbarDropdown"
        >
<!--     @mouseleave="updateDropdownStatus(3000)"
@mouseleave="updateDropdownStatus(3000)"
                @mouseenter="updateDropdownStatus(index)"
-->
            <span :key="indexSub + 1500"
                  v-for="(subItem, indexSub) in item.field_pg_menu_link_sub">


          <router-link
                  class="menu-link"
                  :class="[
              index === 0 ? 'first' : '',
              index + 1 === item.field_pg_menu_link_sub.length ? 'last' : '',
              index >=0 ? 'service-'+(indexSub+1) : ''
            ]"
                  :to="helpers.getMenuUrl(subItem.uri)"
                  @click.native="checkMenu(helpers.getMenuUrl(subItem.uri))"
                  v-if="!helpers.isExternal(helpers.getMenuUrl(subItem.uri))"
          >
            {{ subItem.title}}
          </router-link>
            <a
                    class="menu-link"
                    :class="[
              index === 0 ? 'first' : '',
              index + 1 === item.field_pg_menu_link_sub.length ? 'last' : '',
              index >=0 ? 'service-'+(indexSub+1) : ''
            ]"
                    :href="helpers.getMenuUrl(subItem.uri)"
                    v-else
            >
                {{ subItem.title}}
            </a>
                </span>
        </div>
      </li>
    </ul>
<!--    <div v-else>-->
<!--&lt;!&ndash;      <h1>UL MENU NO IS READY</h1>&ndash;&gt;-->
<!--    </div>-->
  </nav>
</template>

<script>

export default {
  name: "MainMenu",
  data() {
    return {
      showMenu: false,
      templateName: "MainMenu",
      language: this.$i18n.locale,
      helpers: this.$helperList,
      subMenuActive: 5000,
      hideMenu: false,
      subMenuStatus: [
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false
      ],
    };
  },
  props: {
    sectionData: {
      type: Object,
      required: true
    }
  },
   
   watch: {
     $route() {
       this.language = this.$i18n.locale;
       let body = document.querySelector('body');
       let nav = document.querySelector('.hamburger');
       body.classList.remove('open--nav');
       nav.classList.remove('is-active');
     }
  },
  computed: {
    currentClass() {
      return this.showMenu ? "open" : "close";
    }
  },
  methods: {
    updateValue(index){
      return this.subMenuStatus[index];
    },
    updateDropdownStatus(index){
      if(this.subMenuActive === index){
        this.subMenuActive = 5000;
      } else {
        this.subMenuActive = index;
      }
    },
    isExpand(index){
      if(index === this.subMenuActive){
        return true;
      }
      return false;
    },
    openMobileMenu(){
      // console.log("Open mobile menu", id);
      this.subMenuActive = !this.subMenuActive;
    },
    checkMenuParent(menu, index){
      if(this.subMenuStatus[index]){
        this.subMenuStatus[index] = !this.subMenuStatus[index];
      } else {
        this.subMenuStatus[index] = true;
      }

      if(this.isExternal(menu)){
        window.location.replace(menu);
      }
    },
    checkMenu(menu){
      // console.log(menu);
      if(this.isExternal(menu)){
        window.location.replace(menu);
      }
    },
    isExternal(url) {
      return !!url.includes("http");
    },

    toggleShow: function() {
      this.showMenu = !this.showMenu;
    },
    toggleShow2: function() {
      
      this.showMenu = !this.showMenu;
    },
    sameRoute: function(data) {
      let body = document.querySelector('body');
      let nav = document.querySelector('.hamburger');
      if(this.$route.name == data ) {
        body.classList.remove('open--nav');
        nav.classList.remove('is-active');
      }
        
    }

  },
 
  // ready: function() {
  //   document.querySelector('.hamburger').onclick = function (e) {
  //     var body = document.querySelector('body');
  //     var nav = document.querySelector('.hamburger');
  //     body.classList.toggle('open--nav');
  //     nav.classList.toggle('is-active');
  //     e.preventDefault();
  //   }
  // }
};
</script>
<style lang="scss" scoped>
    .dropdown-menu{
        a{
            display: block;
            padding: 10px 15px;
            background-color: #f9f9f9;
            color: #366a5a;
            text-decoration: none;
            border-bottom: 1px solid rgba(54,106,90,.25);
            @media(min-width: 1200px){
                font-size: 13px;
            }
            &:hover{
                text-decoration: none;
                color: #1c2625;
                background-color: #fff;
                border-bottom: 2px solid #fff;
            }
        }
    }
</style>
