<template>
  <section v-if="sectionData" class="pb--s1">
    <div class="wrapper">
      <div class="pb--s1-image" v-if="sectionData.image">
        <img :src="sectionData.image.url" alt="" />
      </div>
      <div class="pb--s1-title">
        <div
          v-if="sectionData.subtitle"
          v-html="sectionData.subtitle"
          class="pb--s1-subtitle"
        ></div>
        <h2
          v-if="sectionData.title"
          v-html="sectionData.title"
          class="display--1 no-break"
        ></h2>
      </div>
      <div
        v-if="sectionData.description"
        v-html="sectionData.description"
        class="pb--s1-intro"
      ></div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'PerksBenefitsContentTop',
  props: {
    sectionData: {
      type: Object,
      required: true
    }
  },
  methods: {}
}
</script>

<style scoped></style>
