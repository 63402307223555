<template>
  <!-- PERKS SECTION 3 -->
  <section v-if="sectionData" class="pb--s3">
    <div class="visible-on-1024">
        <div class="wrapper">
            <router-link :to="sectionBtn.items[0].link.uri" role="button" class="primary-outline-button button button-block">
              {{ sectionBtn.items[0].title }}</router-link>
        </div>
    </div>

    <div class="wrapper" v-if="sectionData.listContent">
      <div class="flex-row has--padding">
        <div class="col-lg-6 col-xs-12">
          <div class="pb--s3-image" v-if="sectionData.listContent[0].image">
            <img
                    :src="sectionData.listContent[0].image.url"
                    :alt="sectionData.listContent[0].image.alt"
            />
          </div>
        </div>
        <div class="col-lg-6 col-xs-12">
          <div class="pb--s3-content">
            <h2
                    v-if="sectionData.listContent[0].title"
                    v-html="sectionData.listContent[0].title"
                    class="display--1 display--inner no-break"></h2>
            <div
                    v-if="sectionData.listContent[0].description"
                    v-html="sectionData.listContent[0].description"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "PerksBenefitsTitleContentBoxesBottom",
  props: {
    sectionData: {
      type: Object,
      required: true
    },
    sectionBtn: {
      type: Object
    },
  },
  methods: {
    getBackground(image) {
      const imageData = image;

      const fullUrl = this.$store.state.hDrupalBaseUrl + "/" + imageData;

      return fullUrl.replace("/public://", "/sites/default/files/");
    },
    fixUrl(uri) {
      const newUri = uri.replace("internal:", "");
      return newUri;
    }
  }
};
</script>

<style scoped></style>
