<template>
  <section v-if="sectionData" class="team-container">
    <div class="wrapper">
      <h2
          v-if="sectionData.title"
          v-html="sectionData.title"
          class="display--1 no-break team-heading"
      ></h2>
      <div
          class="team-member-container team-member-header"
          v-if="getFirstListItem(sectionData.list)"
      >
        <div class="row">
          <div
              v-if="sectionData.list[0].image"
              class="image-container"
          >
            <div class="member-foto">
              <img :src="sectionData.list[0].image.url" alt=""/>
            </div>
          </div>
          <div class="description-container">
            <div class="member-detail">
              <div class="member-header">
                <h3
                    v-if="sectionData.list[0].name"
                    v-html="sectionData.list[0].name"
                    class="member-name"
                ></h3>
                <div
                    v-if="sectionData.list[0].title"
                    v-html="sectionData.list[0].title"
                    class="memeber-job"
                ></div>
              </div>
              <div
                  v-if="sectionData.list[0].description"
                  v-html="sectionData.list[0].description"
                  class="member-info"
              ></div>
            </div>
          </div>
        </div>

      </div>
      <div class="team-grid-container">
        <div
            v-for="(team, index) in removeFirstListItem(sectionData.list)"
            :key="index"
            class="team-member-container"
        >
          <div class="row">
            <div
                v-if="team.image"
                class="image-container"
            >
              <div class="member-foto">
                <img :src="team.image.url" alt=""/>
              </div>
            </div>
            <div class="description-container">
              <h3
                  v-if="team.name"
                  v-html="team.name"
                  class="member-name"
              ></h3>
              <div
                  v-if="team.title"
                  v-html="team.title"
                  class="member-job"
              ></div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </section>
</template>

<script>
export default {
  name: "OurTeamTeamsV2",
  props: {
    sectionData: {
      type: Object,
      required: true
    }
  },
  methods: {
    getFirstListItem(list) {
      return list[0];
    },
    removeFirstListItem(list) {
      const newList = list;
      newList.shift();
      return newList;
    }
  }
};
</script>

<style scoped></style>
