<template>
  <section v-if="sectionData" class="section-about-2">
    <div class="wrapper">
      <h2
        v-if="sectionData.title"
        v-html="sectionData.title"
        class="display--1 no-break display--inner"
      ></h2>
      <div v-if="sectionData.listContent" class="flex-row has--padding">
        <div
          v-for="item in sectionData.listContent"
          :key="item.id"
          class="col-lg-6"
        >
          <div class="sa2-content">
            <h3
              v-if="item.title"
              v-html="item.title"
            ></h3>
            <div
              v-if="item.description"
              v-html="item.description"
              class="content"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "AboutUsTitleContentBoxes",
  props: {
    sectionData: {
      type: Object,
      required: true
    }
  }
};
</script>

<style scoped></style>
