<template>
  <section v-if="sectionData" class="hs-3 section--image-content">
    <div class="wrapper">
      <div class="flex-row has--padding">
        <div class="col-lg-6 col-xs-12 ss--col image-c col-b-s">
          <img
                  :src="sectionData.image.url"
          />
        </div>
        <div class="col-lg-6 col-xs-12 ss--col content-c col-b-s">
          <h2
                  v-if="sectionData.title"
                  v-html="sectionData.title"
                  class="display--1 no-break title c-item"
          ></h2>
          <div class="body-content c-item ss--desc" v-html="sectionData.content" v-if="sectionData.content"></div>
          <div class="link c-item ss--cta" v-if="sectionData.link">
            <a :href="fixUrl(sectionData.link.uri)"
               class="primary-link-button">
              {{ sectionData.link.title }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "BankingTitleImageContent",
  props: {
    sectionData: {
      type: Object,
      required: true
    }
  },
  methods: {
    getBackground(image) {
      const imageData = image;
      // .replace('public://', '')
      const fullUrl = this.$store.state.hDrupalBaseUrl + "/" + imageData;

      return fullUrl.replace("/public://", "/sites/default/files/");
    },
    fixUrl(uri) {
      const newUri = uri.replace("internal:", "");
      return newUri;
    }
  }
};
</script>

<style scoped lang="scss">
  .section--image-content{
    padding-top: 0;
    .col-b-s{
      @media(max-width: 767px){
        padding-left: 20px!important;
        padding-right: 20px!important;
      }
      &.image-c{
        padding-right: 35px;
        @media(max-width: 1024px){
          padding-right: 0;
          padding-left: 0;
        }
      }
      &.content-c{
        padding-left: 100px;
        @media(max-width: 1024px){
          padding-right: 0;
          padding-left: 0;
          padding-top: 40px;
        }
      }
    }
    .ss--desc{
      margin-bottom: 50px;
    }
    .display--1.c-item{
      font-size: 43px;
      @media(max-width: 575px){
        font-size: 22px;
        line-height: 1.27;
        margin-bottom: 30px;
      }
    }
  }
</style>
