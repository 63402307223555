<template>
  <main role="main">
    <div v-if="isReady">
      <div
        class="page-wp art-series-page"
        v-if="content.field_template_name === 'art-series'"
      >
        <about-us-hero-banner
          :section-data="helpers.getSectionBanner(content)"
        />
        <our-team-teams :section-data="helpers.getSectionTeam(content)" />
        <art-series-title-description />
        <art-series-playeros />
      </div>
      <div
        class="page-wp art-series-page"
        v-if="content.field_template_name === 'art-series-2024'"
      >
        <about-us-hero-banner
          :section-data="helpers.getSectionBanner(content)"
        />
        <our-team-teams :section-data="helpers.getSectionTeam(content)" />
        <art-series-title-description2024 />
        <art-series-playeros2024 />
      </div>
      <div
        class="page-wp"
        v-if="content.field_template_name === 'banking-page'"
      >
        <banking-banner :section-data="helpers.getSectionBanner(content)" />
        <banking-content-top
          :section-data="helpers.getSectionContentTop(content)"
        />
        <banking-title-image-content
          :section-data="helpers.getSectionTitleImageContent(content)"
        />
        <banking-title-content-list
          :section-data="helpers.getSectionTitleContentList(content)"
        />
        <bottom-content />
      </div>
      <div
        class="page-wp"
        v-if="content.field_template_name === 'perks-benefits-page'"
      >
        <perks-benefits-hero-banner
          :section-data="helpers.getSectionBanner(content)"
        />
        <perks-benefits-content-top
          :section-data="helpers.getSectionContentTop(content)"
        />
        <section class="pb--s2">
          <perks-benefits-title-content-list
            :section-data="helpers.getSectionTitleContentList(content)"
          />
          <perks-benefits-gallery-list
            :section-data="helpers.getSectionGalleryList(content)"
          />
        </section>
        <perks-benefits-title-content-boxes-bottom
          :section-data="helpers.getSectionTitleContentBoxesBottom(content)"
          :section-btn="helpers.getSectionTitleContentList(content)"
        />
        <bottom-content />
      </div>
      <div class="page-wp" v-if="content.field_template_name === 'about-page'">
        <about-us-hero-banner
          :section-data="helpers.getSectionBanner(content)"
        />
        <about-us-content-top
          :section-data="helpers.getSectionContentTop(content)"
        />
        <about-us-title-content-boxes
          :section-data="helpers.getSectionWTitleContentBoxes(content)"
        />
        <about-us-galleries
          :section-data="helpers.getSectionGalleryList(content)"
        />
        <bottom-content />
      </div>
      <div
        class="page-wp"
        v-if="content.field_template_name === 'our-team-page'"
      >
        <our-team-hero-banner
          :section-data="helpers.getSectionBanner(content)"
        />
        <our-team-teams :section-data="helpers.getSectionTeam(content)" />
        <bottom-content />
      </div>
      <div
          class="page-wp"
          v-if="content.field_template_name === 'our-team-page-2'"
      >
        <our-team-hero-banner
            :section-data="helpers.getSectionBanner(content)"
        />
        <our-team-teams-v2 :section-data="helpers.getSectionTeam(content)" />
        <bottom-content />
      </div>
      <div
        class="page-wp"
        v-if="content.field_template_name === 'contact-us-page'"
      >
        <contact-us-hero-banner
          :section-data="helpers.getSectionBanner(content)"
        />
        <!--        <contact-us-title-content-boxes-->
        <!--          :section-data="helpers.getSectionWTitleContentBoxes(content)"-->
        <!--        />-->
        <contact-us-body v-if="contactIsReady" :section-data="contactInfo" />
        <bottom-content-contact />
      </div>
    </div>
    <speed-bump v-if="isReady" />
  </main>
</template>

<script>
import * as contentAPI from "@/api/content";
import speedBump from "@/components/sections/speedBump";
import BankingBanner from "../components/banking/banner";
import BankingContentTop from "../components/banking/contentTop";
import BankingTitleImageContent from "../components/banking/titleImageContent";
import BankingTitleContentList from "../components/banking/titleContentList";
import BottomContent from "../components/blocks/bottomContent";
import PerksBenefitsHeroBanner from "../components/perksBenefits/heroBanner";
import PerksBenefitsContentTop from "../components/perksBenefits/contentTop";
import PerksBenefitsTitleContentList from "../components/perksBenefits/titleContentList";
import PerksBenefitsGalleryList from "../components/perksBenefits/galleryList";
import PerksBenefitsTitleContentBoxesBottom from "../components/perksBenefits/titleContentBoxesBottom";
import AboutUsHeroBanner from "../components/aboutUs/heroBanner";
import AboutUsContentTop from "../components/aboutUs/contentTop";
import AboutUsTitleContentBoxes from "../components/aboutUs/titleContentBoxes";
import AboutUsGalleries from "../components/aboutUs/galleries";
import OurTeamHeroBanner from "../components/ourTeam/heroBanner";
import OurTeamTeams from "../components/ourTeam/teams";
import ContactUsHeroBanner from "../components/contactUs/heroBanner";
// import ContactUsTitleContentBoxes from "../components/contactUs/titleContentBoxes";
import BottomContentContact from "../components/blocks/bottomContentContact";
import ContactUsBody from "../components/blocks/contactUsBody";
import ArtSeriesTitleDescription from "@/components/art-series/titleDescription";
import ArtSeriesPlayeros from "@/components/art-series/playeros";
import ArtSeriesPlayeros2024 from "@/components/art-series-2024/playeros2024";
import ArtSeriesTitleDescription2024 from "@/components/art-series-2024/titleDescription2024.vue";
import OurTeamTeamsV2 from "@/components/ourTeam/teamsVersion2.vue";

export default {
  name: "Page",
  title() {
    return `Platinum | FirstBank`;
  },
  components: {
    OurTeamTeamsV2,
    ArtSeriesPlayeros2024,
    ArtSeriesTitleDescription2024,
    ArtSeriesPlayeros,
    ArtSeriesTitleDescription,
    ContactUsBody,
    BottomContentContact,
    // ContactUsTitleContentBoxes,
    ContactUsHeroBanner,
    OurTeamTeams,
    OurTeamHeroBanner,
    AboutUsGalleries,
    AboutUsTitleContentBoxes,
    AboutUsContentTop,
    AboutUsHeroBanner,
    PerksBenefitsTitleContentBoxesBottom,
    PerksBenefitsGalleryList,
    PerksBenefitsTitleContentList,
    PerksBenefitsContentTop,
    PerksBenefitsHeroBanner,
    BottomContent,
    BankingTitleContentList,
    BankingTitleImageContent,
    BankingContentTop,
    BankingBanner,
    speedBump
  },
  data() {
    return {
      language: this.$i18n.locale,
      content: null,
      isReady: false,
      helpers: this.$helperList,
      title: "",
      default: {
        uuid: null,
        nid: null,
        title: null,
        type: null,
        uri: null
      },
      contactIsReady: false,
      contactData: {
        uuid: "eb24641f-a042-4fdb-bc00-d7a063d0a83b",
        nid: 702,
        title: "Footer",
        type: "fb_block_section"
      },
      contactInfo: null,
      allContent: [],

      currentPage: []
    };
  },
  created() {
    this.loadContent();
    this.scrollContent();
  },
  mounted() {
    document.body.classList.add("inner--page");
  },
  computed: {},
  methods: {
    validateLanguage() {
      if (this.language) {
        this.language = this.$i18n.locale;
      }
    },
    setBasicContentInfo() {
      this.validateLanguage();
      const pathRoute = {
        // path: `/${this.$route.params.general_path}`,
        path: `/${this.language}/${this.$route.params.general_path}`,
        lang: this.language
      };
      // console.log("PATH ROUTE", pathRoute, "ROUTE->", this.$route);
      const pathData = this.$store.getters.getUrlData(pathRoute);
      if (pathData[0]) {
        this.default = pathData[0];
      } else {
        console.log("10 PAGE - NO have content", pathRoute);
      }
    },
    setBasicContentInfoTrans() {
      this.validateLanguage();
      let uuid = null;
      if (this.default.uuid) {
        uuid = this.default.uuid;
      } else {
        console.log("11 Page - NO UUID DEFINED");
      }
      const pathRoute = {
        uuid: uuid,
        lang: this.language
      };
      const pathData = this.$store.getters.getUrlDataByUUID(pathRoute);
      if (pathData) {
        this.default = pathData[0];
        const general_path = this.default.uri.split("/");

        this.$router
          .push({
            name: "Page",
            params: { general_path: general_path[2] }
          })
          .catch(() => {});
      } else {
        console.log("11 PAGE - NO translate CONTENT");
      }
    },
    loadContentTrans() {
      this.setBasicContentInfoTrans();
      this.getContent();
    },
    setAddressInfo() {
      this.contactIsReady = false;
      let content = null;
      contentAPI
        .getContent({
          type: this.contactData.type,
          uuid: this.contactData.uuid,
          lang: this.language
        })
        .then(data => {
          content = data.data.data;
          this.contactInfo = content;
        })
        .catch(error => {
          console.log("ERROR ON setContent function", error);
          content = null;
        })
        .finally(() => {
          if (content) {
            this.contactIsReady = true;
          }
          // this.content = content;
          // this.isReady = true;
        });
    },
    getContent() {
      this.validateLanguage();
      this.isReady = false;
      let content = null;
      contentAPI
        .getContent({
          type: this.default.type,
          uuid: this.default.uuid,
          lang: this.language
        })
        .then(data => {
          content = data.data.data;
        })
        .catch(error => {
          console.log("ERROR ON setContent function", error);
          content = null;
        })
        .finally(() => {
          this.content = content;
          this.isReady = true;
          const currentClass = this.$helperList.setContentTypeClass(
            this.default.type
          );
          const templateClass = this.$helperList.setTemplateClass(
            this.content.field_custom_template
          );
          if (templateClass === "faq-credit") {
            document.body.classList.add("faq-page");
            this.content.field_custom_template = "faq";
            this.faqType = "credit";
          }
          document.body.classList.add(currentClass);
          document.body.classList.add(templateClass);
          if (this.content.field_template_name === "contact-us-page") {
            this.setAddressInfo();
          }
        });
    },
    loadContent() {
      // const urlMap = this.$store.getters["urlMap"];
      this.setBasicContentInfo();
      if (this.default.type && this.default.uuid && this.language) {
        this.getContent();
      } else {
        console.log("WE DONT HAVE BASIC DATA", this.default);
      }
    },
    scrollContent() {
      if (location.hash) {
        setTimeout(function() {
          const hash = location.hash.replace("#", "");
          console.log("Hash", hash);
          document.getElementById(hash).scrollIntoView();
        }, 2000);
      }
    }
  },
  watch: {
    $route() {
      const oldLanguage = this.language;
      this.language = this.$i18n.locale;
      if (oldLanguage !== this.language) {
        this.loadContentTrans();
      } else {
        if (this.isReady) {
          this.loadContent();
        }
      }

      this.scrollContent();
      // const routeThis = this;
      // routeThis.addModalJavascript();
    },
    content() {
      if (this.content) {
        document.title = `Platinum | FirstBank — ${this.content.title}`;
      }
    }
  }
};
</script>

<style lang="scss">
.site-navigation ul li {
  &:last-child {
    display: flex !important;
  }
}
</style>
