import { Trans } from "@/plugins/translation";
// ==== VIEWS
import homePage from "../views/homePage.vue";
import cookiesPage from "../views/cookies.vue";
import page from "../views/page";
import Home from "../views/Home";
export default [
  {
    path: "/:lang",
    component: {
      template: "<router-view></router-view>"
    },
    beforeEnter: Trans.routeMiddleware,
    children: [
      {
        path: "cookies",
        name: "Cookies",
        component: cookiesPage,
        meta: { title: "Cookies" }
      },
      {
        path: "home",
        name: "HomeDev",
        component: Home
      },
      {
        path: ":general_path",
        name: "Page",
        component: page
      },
      {
        path: "",
        name: "Home",
        component: homePage,
        meta: { title: "Home" }
      }
    ]
  },
  {
    // Redirect user to supported lang version.
    path: "*",
    // eslint-disable-next-line no-unused-vars
    redirect(to) {
      return Trans.getUserSupportedLang();
    }
  }
];
