<template>
  <section id="next" v-if="sectionData" class="hs-2">
    <div class="wrapper">
      <div class="row middle-xs">
        <div v-if="sectionData.title"
             class="col-lg-6 col-xs-12">
          <h2
                  v-html="sectionData.title"
                  class="display--1"
          ></h2>
        </div>
        <div v-if="sectionData.description"
             class="col-lg-6 col-xs-12">
          <div
                  v-html="sectionData.description"
                  class="hs-2-text"
          ></div>
        </div>
      </div>
      <div class="our-services pt30">
        <button
                v-if="sectionData.listTitle"
                type="button"
                class="hidden-lg-none ostitle"
                aria-label="Service Menu"
                aria-controls="navigation"
        >
          {{ sectionData.listTitle }}
          <i class="fa fa-chevron-down" aria-hidden="true"></i>
        </button>
        <ul v-if="sectionData.listIcons">
          <li v-for="iconItem in sectionData.listIcons" :key="iconItem.id">
            <a
                    v-if="iconItem.link"
                    :href="iconItem.link.uri"
                    class="service-icon"
            >
                          <span class="sicon"
                          ><img
                                  :src="iconItem.icon.url"
                          /></span>
              <span class="icon-title">{{ iconItem.title }}</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </section>

</template>

<script>
export default {
  name: "contentTop",
  props: {
    sectionData: {
      type: Object,
      required: true
    }
  },
  mounted(){
    setTimeout(() => {
        var elem = document.querySelector('.ostitle');
            if (elem) {
                elem.onclick = function (e) {
                  var menu = document.querySelector('.our-services');
                  menu.classList.toggle('is--active');
                  e.preventDefault();
                }
            }
        },
            300);
  },
  methods: {},
  computed: {}
};
</script>

<style scoped></style>
