<template>
  <div v-if="sectionData" class="page--header ph--style-2 ph--alt">
    <div class="wrapper">
      <div class="flex-row">
        <div class="col-lg-6 col-xs-12">
          <div class="ph-s2-content">
            <h1
              v-if="sectionData.subtitle"
              v-html="sectionData.subtitle"
              class="display--4"
            ></h1>
            <h2
              v-if="sectionData.title"
              v-html="sectionData.title"
              class="display--2"
            ></h2>
          </div>
        </div>

        <div class="col-lg-6 col-xs-12">
          <div
            v-if="sectionData.description"
            v-html="sectionData.description"
            class="ph-s2-desc"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "perksBenefitsHeroBanner",
  props: {
    sectionData: {
      type: Object,
      required: true
    }
  },
  methods: {}
};
</script>

<style scoped></style>
