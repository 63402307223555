<template>
  <section v-if="sectionData" class="hs-5">
    <div class="wrapper">
      <h2
        v-if="sectionData.cb_title"
        v-html="sectionData.cb_title"
        class="display--1 no-break"
      ></h2>
      <div
        v-if="sectionData.listContent"
        class="flex-row psboxes psboxes-center"
      >

          <div
            v-for="item in sectionData.listContent"
            :key="item.id"
            class="col-lg-3 col-xs-12 col-md-6 pscol"
          >
            <div class="psbox">
              <div v-if="item.image" class="psbox-icon">
                <img
                  :src="item.image.url"
                  alt="Banking"
                />
              </div>
              <h3
                v-if="item.title"
                v-html="item.title"
                class="psbox-title"
              ></h3>
              <div
                v-if="item.description"
                v-html="item.description"
                class="psbox-desc"
              ></div>
              <router-link
                v-if="item.link && !helpers.isExternal(item.link.uri)"
                :to="item.link.uri"
                class="psbox-link"
              >
                {{ item.link.title }}
              </router-link>
              <a
                      class="psbox-link"
                      :href="item.link.uri"
                      v-if="item.link && helpers.isExternal(item.link.uri)"
              >
                {{ item.link.title }}
              </a>
            </div>
          </div>
      
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "TitleContentBoxesBottom",
  data() {
    return {
      helpers: this.$helperList,
    }
  },
  props: {
    sectionData: {
      type: Object,
      required: true
    }
  },
  methods: {
    getBackground(image) {
      const imageData = image;

      const fullUrl = this.$store.state.hDrupalBaseUrl + "/" + imageData;

      return fullUrl.replace("/public://", "/sites/default/files/");
    },
    fixUrl(uri) {
      const newUri = uri.replace("internal:", "");
      return newUri;
    }
  }
};
</script>

<style scoped></style>
