<template>
  <section v-if="sectionData" class="how-we-can-help">
    <div class="wrapper">
      <h2
        v-if="sectionData.field_header_title"
        v-html="sectionData.field_header_title.value"
        class="display--1 no-break"
      ></h2>
      <div class="contact-body" v-if="sectionData.body">
        <div class="body-wrapper" v-html="sectionData.body.value">

        </div>
      </div>
      <div class="contact-us-row">
        <div class="col item-1">
          <h4
            v-if="sectionData.field_list_title"
            v-html="sectionData.field_list_title"
            class="no-break sub-title"
          ></h4>
          <div
            v-for="item in sectionData.field_items_1"
            class="contact--info"
            :key="item.id"
          >
            <div class="cititle" v-if="item.field_pg_title">
              <strong>{{ item.field_pg_title }}</strong>
            </div>
            <div
              class="cidesc"
              v-if="item.field_pg_description"
              v-html="item.field_pg_description.value"
            ></div>
          </div>
        </div>
        <div class="col item-2">
          <h4
            v-if="sectionData.field_list_title_2"
            v-html="sectionData.field_list_title_2"
            class="no-break sub-title"
          ></h4>
          <div
            v-for="item in sectionData.field_items_2"
            class="contact--info"
            :key="item.id"
          >
            <div class="cititle" v-if="item.field_pg_title">
              <strong>{{ item.field_pg_title }}</strong>
            </div>
            <div
              class="cidesc"
              v-if="item.field_pg_description"
              v-html="item.field_pg_description.value"
            ></div>
          </div>
        </div>
        <div class="col item-3">
          <h4
            v-if="sectionData.field_list_title_3"
            v-html="sectionData.field_list_title_3"
            class="no-break sub-title"
          ></h4>
          <div
            v-for="item in sectionData.field_items_3"
            class="contact--info"
            :key="item.id"
          >
            <div class="cititle" v-if="item.field_pg_title">
              <strong>{{ item.field_pg_title }}</strong>
            </div>
            <div
              class="cidesc"
              v-if="item.field_pg_description"
              v-html="item.field_pg_description.value"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "contactUsBody",
  data() {
    return {
      helpers: this.$helperList
    };
  },
  props: {
    sectionData: {
      type: Object,
      required: true
    }
  }
};
</script>

<style scoped lang="scss">
.contact--info {
  .cidesc {
    span {
      color: #366a5a !important;
    }
  }
}
.contact-us-row{
  @media(max-width: 1024px){
    display: block;
  }
}
h2.display--1{
  @media(max-width: 1024px){
    width: 100%;
    text-align: center;
  }
}
.contact-us-row .col {
  @media (min-width: 1024px) {
    padding-right: 70px !important;
    max-width: 325px !important;
    &:last-child {
      padding-right: 0 !important;
    }
  }
  @media(max-width: 1024px){
    width: 100%!important;
    max-width: 800px;
    clear: both;
    padding-left: 0;
    padding-right: 0;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
  .sub-title {
    font-size: 18px;
    color: #7b7b7b;
    font-weight: bold;
    @media (max-width: 1024px) {
      width: 100%;
      text-align: center;
      font-size: 16px;
    }
  }
  &.item-1,
  &.item-2 {
    .contact--info {
      &:last-child {
        @media (max-width: 1024px) {
          padding-bottom: 40px;
          border-bottom: 1px solid #d8d8d8;
          margin-left: 20px;
          margin-right: 20px;
          margin-bottom: 0;
        }
      }
    }
  }

}
</style>
