<template>
  <div class="wrapper">
    <div class="perks-slider" :class="galleryReady">
      <div class="wrapperr">
        <div class="perks-slider-holder events-gallery events-on-about">
          <splide class="splide" :options="options">
            <splide-slide
              v-for="item in sectionData.list"
              :key="item.id"
              class="cgbox"
            >
              <div class="perk-slide-card">
                <div class="psc-image" v-if="item.image_list[0]">
                  <img :src="item.image_list[0].url" />
                  <div class="show-more-link" v-if="item.modal">
                    <a href="javascript:void(0)" @click="showModal(item)">{{
                      $t("view_gallery")
                    }}</a>
                  </div>
                </div>
                <div class="psc-image" v-else>
                  <div class="show-more-link" v-if="item.modal">
                    <a href="javascript:void(0)" @click="showModal(item)">{{
                        $t("view_gallery")
                      }}</a>
                  </div>
                </div>
                <div v-if="item.title" class="psc-content">
                  <h3 class="psc-title">
                    {{ item.title }}
                  </h3>
                  <div v-if="item.description" v-html="item.description"></div>
                </div>
              </div>
            </splide-slide>
            <template v-slot:controls>
              <!--<div class="cgbottom"></div>-->
              <div class="splide__arrows custom__arrows">
                <button class="splide__arrows splide__arrow--prev">
                  <svg
                    style="transform: scale(1,-1)"
                    xmlns="http://www.w3.org/2000/svg"
                    width="54.4"
                    height="19.2"
                    viewBox="0 0 54.4 19.2"
                  >
                    <path
                      d="M53.437,8.6H3.309l7.124-6.99a.932.932,0,0,0,0-1.336.976.976,0,0,0-1.362,0L.409,8.777A.937.937,0,0,0,0,9.547s0,.009,0,.014,0,.02,0,.03a.922.922,0,0,0,.052.264.9.9,0,0,0,.069.151.947.947,0,0,0,.082.121.885.885,0,0,0,.074.109l8.853,8.687a.976.976,0,0,0,1.362,0,.932.932,0,0,0,0-1.336l-7.232-7.1H53.437a.945.945,0,1,0,0-1.889Z"
                      fill="#028953"
                    />
                  </svg>
                </button>
                <button class="splide__arrows splide__arrow--next">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="54.4"
                    height="19.2"
                    viewBox="0 0 54.4 19.2"
                  >
                    <path
                      d="M-53.437,8.6H-3.309l-7.124-6.99a.932.932,0,0,1,0-1.336.976.976,0,0,1,1.362,0l8.663,8.5A.937.937,0,0,1,0,9.547s0,.009,0,.014,0,.02,0,.03a.922.922,0,0,1-.052.264.9.9,0,0,1-.069.151.947.947,0,0,1-.082.121.885.885,0,0,1-.074.109l-8.853,8.687a.969.969,0,0,1-.681.277.969.969,0,0,1-.681-.277.932.932,0,0,1,0-1.336l7.232-7.1H-53.437a.954.954,0,0,1-.963-.945A.954.954,0,0,1-53.437,8.6Z"
                      transform="translate(54.4)"
                      fill="#028953"
                    />
                  </svg>
                </button>
              </div>
            </template>
          </splide>
          <div class="gallery-list">
            <modal name="galleryModal" height="auto">
              <div class="modal-dialog-galleries">
                <div class="modal-content-wrapper">
                  <div class="modal-header">
                    <button
                      type="button"
                      class="close"
                      @click="$modal.hide('galleryModal')"
                    >
                      <i aria-hidden="true" class="fa fa-chevron-left"></i>
                      {{ $t("back_gallery") }}
                    </button>
                    <h2
                      v-if="language === 'es'"
                      class="display--1 display--inner no-break"
                    >
                      Eventos <span>exclusivos</span>
                    </h2>
                    <h2 v-else class="display--1 display--inner no-break">
                      Exclusive <span>Events</span>
                    </h2>
                  </div>
                  <div class="modal-body" v-if="currentGallery">
                    <div class="modal-content">
                      <h4 v-if="currentGallery.title">
                        {{ currentGallery.title }}
                      </h4>
                      <div
                        v-if="currentGallery.description"
                        class="modal-splide-description"
                      >
                        <p v-html="currentGallery.description"></p>
                      </div>
                    </div>

                    <div class="modal-splide-wrapper">
                      <splide-modal-media
                        :media-list="currentGallery.mediaList"
                      ></splide-modal-media>
                    </div>
                  </div>
                </div>
              </div>
            </modal>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Splide, SplideSlide } from "@splidejs/vue-splide";
import SplideModalMedia from "@/components/blocks/splideModalMedia.vue";

export default {
  name: "PerksBenefitsGalleryList",
  components: {
    SplideModalMedia,
    Splide,
    SplideSlide
  },
  props: {
    sectionData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      // options: {
      //   type: "loop",
      //   autoplay: false,
      //   fixedWidth: 440,
      //   perPage: 3,
      //   cover: true,
      //   pagination: true,
      //   gap: "20px",
      //   breakpoints: {
      //     1024: {
      //       perPage: 2
      //     },
      //     767: {
      //       perPage: 1,
      //       fixedWidth: 275,
      //       gap: "15px"
      //     }
      //   }
      // }
      gallery: {},
      language: this.$i18n.locale,
      helpers: this.$helperList,
      content: null,
      options: {
        type: "loop",
        autoplay: false,
        pauseOnHover: false,
        autoWidth: false,
        gap: "20px",
        clones: 0,
        perPage: 3,
        breakpoints: {
          800: {
            perPage: 1,
            fixedWidth: 265,
            cover: true
          }
        }
      },
      currentGallery: null,
      currentGalleryTitle: null
    };
  },
  methods: {
    galleryReady() {
      console.log(this.sectionData);
      this.content = this.sectionData;
      return "ready";
    },
    validateModal(nid) {
      console.log("NID", nid);
      this.getGallery(nid);
      return "ready-2";
    },

    getGallery(nid) {
      const filter = {
        nid: nid,
        lang: this.language
      };
      console.log("Filter", filter);
      const gallery = this.$store.getters.getBlockContent(filter);
      let galleryData = null;
      if (gallery.length > 0) {
        galleryData = this.helpers.getMediaGalleryList(gallery[0]);
      }

      return galleryData;
    },
    showModal(key) {
      const modalData = this.getGallery(key.modal);
      this.$set(this, "currentGallery", modalData);
      this.$set(this, "currentGalleryTitle", key.title);
      this.$modal.show("galleryModal");
    },
    hideModal(key) {
      this.$set(this, "currentGalleryIndex", key);
      this.$modal.hide("galleryModal");
    }
  }
};
</script>

<style scoped lang="scss">
.psc-image {
  position: relative;
  .show-more-link {
    position: absolute;
    z-index: 5;
    display: flex;
    align-items: end;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to bottom, rgba(89, 89, 89, 0) -15%, #212121);
    padding-bottom: 30px;
    a{
      width: 70%;
      height: 40px;
      margin-left: auto;
      margin-right: auto;
      display: block;
    }
  }
}
</style>
