<!--gallery_subtitle: contentData.field_gallery_subtitle,-->
<!--gallery_title: contentData.field_gallery_title,-->
<!--gallery_description: contentData.field_gallery_description,-->
<!--gallery_item: contentData.field_gallery_item,-->
<!--gallery_label_link: contentData.field_gallery_label_link-->

<template>
  <div id="gallery" v-if="sectionData">
    <section v-if="sectionData.title" class="section-about-3 gallery-on-about">
      <div class="wrapper">
        <div class="sa3-content">
          <div class="flex-row">
            <div class="col-lg-9">
              <div
                v-if="sectionData.subTitle"
                v-html="sectionData.subTitle"
                class="display--4"
              ></div>
              <h2
                v-if="sectionData.title"
                v-html="sectionData.title"
                class="display--1 display--inner no-break"
              ></h2>
              <div
                v-if="sectionData.description"
                v-html="sectionData.description"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section
      v-if="sectionData.list"
      class="columns-gallery secondary-bg-color gallery-on-about"
    >
      <div class="wrapper">
        <div class="cg-inner">
          <!-- Slider start -->
          <splide class="splide" :options="options">
            <splide-slide
              v-for="(item, key) in sectionData.list"
              :key="key"
              class="cgbox"
            >
              <div class="cgbox-inner">
                <div class="cgbox-image">
                  <img :src="item.image_list[0].url" alt="" />
                </div>
                <div v-if="item.title" class="cgbox-content">
                  <div class="cgbox-desc">
                    <h4>
                      {{ item.title }}
                    </h4>
                    <div
                      v-if="item.description"
                      v-html="item.description"
                    ></div>
                    <span v-if="item.url_link">
                      <a
                        :href="item.url_link"
                        v-if="sectionData.openLink"
                        >{{ sectionData.openLink }}</a
                      >
                      <a
                        v-else
                        :href="item.url_link"
                        >{{ $t("view_gallery") }}</a
                      >
                    </span>
                    <span v-else>
                      <a
                        href="javascript:void(0)"
                        v-if="sectionData.openLink"
                        @click="showModal(key)"
                        >{{ sectionData.openLink }}</a
                      >
                      <a
                        v-else
                        href="javascript:void(0)"
                        @click="showModal(key)"
                        >{{ $t("view_gallery") }}</a
                      >
                    </span>
                  </div>
                </div>
              </div>
            </splide-slide>

            <!-- Slider Controls -->
            <template v-slot:controls>
              <div class="cgbottom"></div>
              <div class="splide__arrows custom__arrows">
                <button class="splide__arrows splide__arrow--prev">
                  <svg
                    style="transform: scale(1,-1)"
                    xmlns="http://www.w3.org/2000/svg"
                    width="54.4"
                    height="19.2"
                    viewBox="0 0 54.4 19.2"
                  >
                    <path
                      d="M53.437,8.6H3.309l7.124-6.99a.932.932,0,0,0,0-1.336.976.976,0,0,0-1.362,0L.409,8.777A.937.937,0,0,0,0,9.547s0,.009,0,.014,0,.02,0,.03a.922.922,0,0,0,.052.264.9.9,0,0,0,.069.151.947.947,0,0,0,.082.121.885.885,0,0,0,.074.109l8.853,8.687a.976.976,0,0,0,1.362,0,.932.932,0,0,0,0-1.336l-7.232-7.1H53.437a.945.945,0,1,0,0-1.889Z"
                      fill="#028953"
                    />
                  </svg>
                </button>
                <button class="splide__arrows splide__arrow--next">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="54.4"
                    height="19.2"
                    viewBox="0 0 54.4 19.2"
                  >
                    <path
                      d="M-53.437,8.6H-3.309l-7.124-6.99a.932.932,0,0,1,0-1.336.976.976,0,0,1,1.362,0l8.663,8.5A.937.937,0,0,1,0,9.547s0,.009,0,.014,0,.02,0,.03a.922.922,0,0,1-.052.264.9.9,0,0,1-.069.151.947.947,0,0,1-.082.121.885.885,0,0,1-.074.109l-8.853,8.687a.969.969,0,0,1-.681.277.969.969,0,0,1-.681-.277.932.932,0,0,1,0-1.336l7.232-7.1H-53.437a.954.954,0,0,1-.963-.945A.954.954,0,0,1-53.437,8.6Z"
                      transform="translate(54.4)"
                      fill="#028953"
                    />
                  </svg>
                </button>
              </div>
            </template>
          </splide>
          <div class="gallery-list">
            <modal name="galleryModal" height="auto">
              <div class="modal-dialog-galleries">
                <div class="modal-content-wrapper">
                  <div class="modal-header">
                    <button
                      type="button"
                      class="close"
                      @click="$modal.hide('galleryModal')"
                    >
                      <i aria-hidden="true" class="fa fa-chevron-left"></i>
                      {{ $t("back_gallery") }}
                    </button>
                    <h2
                      v-if="sectionData.title"
                      v-html="sectionData.title"
                      class="display--1 display--inner no-break"
                    ></h2>
                  </div>
                  <div class="modal-body">
                    <div class="modal-content">
                      <h4 v-if="sectionData.list[currentGalleryIndex].title">
                        {{ sectionData.list[currentGalleryIndex].title }}
                      </h4>
                      <div
                        v-if="
                          sectionData.list[currentGalleryIndex]
                            .image_description
                        "
                        class="modal-splide-description"
                      >
                        <p
                          v-html="
                            sectionData.list[currentGalleryIndex]
                              .image_description
                          "
                        ></p>
                      </div>
                    </div>

                    <div class="modal-splide-wrapper">
                      <c-splice-modal
                        :imageList="
                          sectionData.list[currentGalleryIndex].image_list
                        "
                        :descriptionList="
                          sectionData.list[currentGalleryIndex].description_list
                        "
                      ></c-splice-modal>
                    </div>
                  </div>
                </div>
              </div>
            </modal>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { Splide, SplideSlide } from "@splidejs/vue-splide";
// import LightBox from 'vue-image-lightbox'
import CSpliceModal from "@/components/blocks/splideModal.vue";
export default {
  name: "AboutUsGalleries",
  components: {
    CSpliceModal,
    Splide,
    SplideSlide
  },
  props: {
    sectionData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      options: {
        type: "loop",
        autoplay: false,
        pauseOnHover: false,
        autoWidth: false,
        gap: "20px",
        clones: 0,
        perPage: 3,
        breakpoints: {
          800: {
            perPage: 1,
            fixedWidth: 265,
            cover: true
          }
        }
      },
      currentGalleryIndex: 0
    };
  },
  mounted() {
    if (this.$route.hash) {
      setTimeout(() => {
        this.$nextTick(() => {
          const el = document.querySelector(this.$route.hash);
          el && el.scrollIntoView();
        });
      }, 300);
    }
  },
  methods: {
    getBackground(image) {
      const imageData = image;
      // .replace('public://', '')
      const fullUrl = this.$store.state.hDrupalBaseUrl + "/" + imageData;

      return fullUrl.replace("/public://", "/sites/default/files/");
    },
    fixUrl(uri) {
      const newUri = uri.replace("internal:", "");
      return newUri;
    },
    getMediaData(list) {
      const listData = list.map(item => {
        return {
          thumb: this.getBackground(item),
          src: this.getBackground(item),
          caption: ""
        };
      });
      return listData;
    },
    openGallery(key) {
      this.$set(this, "currentGalleryIndex", key);
      this.$refs.lightbox.showImage(0);
    },
    showModal(key) {
      this.$set(this, "currentGalleryIndex", key);
      this.$modal.show("galleryModal");
    },
    hideModal(key) {
      this.$set(this, "currentGalleryIndex", key);
      this.$modal.hide("galleryModal");
    }
  }
};
</script>

<style lang="scss">
.secondary-bg-color.gallery-on-about {
  padding-left: 0 !important;
  padding-right: 0 !important;
  .cgbox-image {
    text-align: center;
  }
}
.v--modal-overlay .v--modal-box {
  width: 90% !important;
  left: auto !important;
  right: auto !important;
  margin-left: auto;
  margin-right: auto;
  /*max-height: 95vh;*/
  overflow-y: auto;
  @media (min-width: 1400px) {
    max-width: 1300px;
  }
  @media (min-width: 1300px) {
    max-width: 1250px;
  }
  @media (min-width: 1200px) {
    max-width: 1150px;
  }
  @media (max-width: 850px) {
    width: 100% !important;
    border: none;
    background: #fff;
    border-radius: 0;
  }
}
.modal-dialog-galleries {
  width: 80%;
  @media (max-width: 1300px) {
    width: 90%;
  }
  margin: 5% 10%;
  padding: 0;
  @media (max-width: 1450px) {
    margin: 50px auto 20px;
  }
  @media (max-width: 1024px) {
    margin: 40px auto 20px;
  }
  @media (max-width: 850px) {
    width: 100%;
    padding: 4%;
    height: 100vh;
    margin: 0;
    max-height: 500px;
  }
  @media (max-width: 500px) {
    padding: 4%;
  }
  .modal-header,
  .modal-body {
    width: 100%;
    clear: both;
  }
}
.modal-dialog-galleries .modal-header {
  h2 {
    float: left;
    display: inline-block;
    font-size: 33px;
    letter-spacing: -0.41px;
    line-height: 1.2;
    margin-bottom: 28px;
    @media (max-width: 1024px) {
      width: 100%;
      clear: both;
      text-align: center;
      font-size: 28px;
    }
    @media (max-width: 850px) {
      font-size: 20px;
      margin-bottom: 8px;
    }
  }
  .close {
    display: inline-block;
    background: #fff;
    border: 2px solid #366a5a;
    color: #366a5a;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1.71px;
    width: 100px;
    height: 40px;
    padding-top: 5px;
    i {
      font-size: 14px;
    }
    float: right;
    &:hover {
      color: #fff;
      background: #366a5a;
    }

    @media (max-width: 1024px) {
      display: block;
      clear: both;
      margin-left: auto;
      margin-right: auto;
      float: none;
      margin-bottom: 18px;
    }
  }
}
.modal-dialog-galleries .modal-body {
  .modal-content {
    h4 {
      font-size: 21px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.3;
      letter-spacing: normal;
      text-align: center;
      width: 100%;
      color: #366a5a;
      margin-bottom: 16px;
      @media (max-width: 1024px) {
        font-size: 18px;
      }
      @media (max-width: 850px) {
        font-size: 14px;
        line-height: 1.1;
        margin-bottom: 14px;
      }
    }
    p {
      line-height: 1.2;
      letter-spacing: -0.29px;
      text-align: center;
      color: #000;
      font-size: 16px;
      @media (max-width: 850px) {
        font-size: 14px;
        line-height: 1.1;
      }
    }
    .modal-splide-description {
      width: 100%;
      clear: both;
      margin-bottom: 20px;
      overflow: hidden;
      @media (max-width: 800px) {
        margin-bottom: 12px;
      }
      p {
        margin-bottom: 0;
        @media (max-width: 800px) {
          font-size: 12px;
        }
      }
    }
  }
  .modal-splide-wrapper {
  }
}
.modal-dialog-galleries .modal-splide-wrapper {
}
</style>
