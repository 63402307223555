<template>

  <section class="hs-4" >
    <splide id="hs4slider" class="splide" :options="options" v-if="sectionData.image_list.length > 1">
      <splide-slide v-for="item in sectionData.image_list" :key="item.id" >
        <div class="splide__image">
          <img
            v-if="item.image"
            :src="item.image.url"
            alt=""
          />
        </div>
        <div class="slide__caption" v-if="item.field_pg_title_w_format">
          <div class="wrapper">
            <div class="flex-row">
              <div class="col-md-8 col-xs-12">
                <div
                  class="sc__title"
                  v-html="item.title"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </splide-slide>
      <template v-slot:controls >
        <div class="splide__arrows custom__arrows">
          <div class="wrapper">
            <div class="flex-row">
              <div class="col-md-offset-8 col-md-4 col-xs-12 sacol">
                <button class="splide__arrows splide__arrow--prev">
                  <svg
                    style="transform: scale(1,-1)"
                    xmlns="http://www.w3.org/2000/svg"
                    width="54.4"
                    height="19.2"
                    viewBox="0 0 54.4 19.2"
                  >
                    <path
                      d="M53.437,8.6H3.309l7.124-6.99a.932.932,0,0,0,0-1.336.976.976,0,0,0-1.362,0L.409,8.777A.937.937,0,0,0,0,9.547s0,.009,0,.014,0,.02,0,.03a.922.922,0,0,0,.052.264.9.9,0,0,0,.069.151.947.947,0,0,0,.082.121.885.885,0,0,0,.074.109l8.853,8.687a.976.976,0,0,0,1.362,0,.932.932,0,0,0,0-1.336l-7.232-7.1H53.437a.945.945,0,1,0,0-1.889Z"
                      fill="#028953"
                    />
                  </svg>
                </button>
                <button class="splide__arrows splide__arrow--next">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="54.4"
                    height="19.2"
                    viewBox="0 0 54.4 19.2"
                  >
                    <path
                      d="M-53.437,8.6H-3.309l-7.124-6.99a.932.932,0,0,1,0-1.336.976.976,0,0,1,1.362,0l8.663,8.5A.937.937,0,0,1,0,9.547s0,.009,0,.014,0,.02,0,.03a.922.922,0,0,1-.052.264.9.9,0,0,1-.069.151.947.947,0,0,1-.082.121.885.885,0,0,1-.074.109l-8.853,8.687a.969.969,0,0,1-.681.277.969.969,0,0,1-.681-.277.932.932,0,0,1,0-1.336l7.232-7.1H-53.437a.954.954,0,0,1-.963-.945A.954.954,0,0,1-53.437,8.6Z"
                      transform="translate(54.4)"
                      fill="#028953"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </template>
    </splide>
   <splide id="hs4slider" class="splide" :options="noarrows" v-else>
      <splide-slide v-for="item in sectionData.image_list" :key="item.id" >
        <div class="splide__image">
          <img
            v-if="item.image"
            :src="item.image.url"
            alt=""
          />
        </div>
        <div class="slide__caption" v-if="item.title">
          <div class="wrapper">
            <div class="flex-row">
              <div class="col-md-8 col-xs-12">
                <div
                  class="sc__title"
                  v-html="item.title"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </splide-slide>
   </splide>
    
  </section>
</template>

<script>
import { Splide, SplideSlide } from "@splidejs/vue-splide";
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
export default {
  name: "imageSlider",
  components: {
    Splide,
    SplideSlide
  },
  props: {
    sectionData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      options: {
        cover: true,
        pagination: false,
        autoplay: true
      },noarrows: {
        cover: true,
        pagination: false,
        autoplay: true,
        arrows:false
      }
      
      
    };
  },
  methods: {}
};
</script>

<style scoped></style>
