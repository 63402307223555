<template>
  <div
          id="alertMessageBox"
    class="message-section section-gm"
    :class="[close ? 'close-box' : '']"
          ref="alertMessageBoxRef"
    v-if="isValidDate()"
  >
    <div class="container-xl">
      <div class="alert-wrapper">
        <div class="content">
          <span class="alert-message content-sec"
            ><img src="@/assets/images/icons/alert-icon.svg" />
            <span class="text" v-html="allContent[0].body.value" />
          </span>
          <span class="close-block content-sec">
            <a href="#" @click="closeMessage()">
              <img src="@/assets/images/circle-x.svg" class="desktop"/>
              <img src="@/assets/images/x.svg" class="mobile"/>
            </a>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex';
export default {
  name: "AlertMessage",
  data: function() {
    return {
      isReady: false,
      language: this.$i18n.locale,
      template: "general",
      content: null,
      close: false
    };
  },
  methods: {
    isValidDate() {
      if(!this.allContent){
        return false;
      }
      if(!Array.isArray(this.allContent)){
        return false;
      }
      const start = this.allContent[0].field_date_start;
      const end = this.allContent[0].field_enddate;
      const currentDate = this.$moment().format("YYYY-MM-DD") + 'T' + this.$moment().format("HH:mm:ss+00:00");
      const startValid = this.$moment(start).isSameOrBefore(currentDate);
      const endValid = this.$moment(currentDate).isSameOrBefore(end);
      const allIsValid = (startValid === true && endValid === true);

      if(allIsValid){
        document.body.classList.add('page-with-message');
      }
      return allIsValid;
    },
    closeMessage() {
      this.close = true;
    },
    updateLanguage() {
      this.language = this.$i18n.locale;
    },
    getLangContent() {
      let content = null;
      const template = this.template;
      content = this.$store.getters.getMessage(template);

      if (content[0]) {
        return content[0];
      }

      return null;
    },
    loadContent: function() {
      let localThis = this;
      let content = localThis.getLangContent();
      if (!content) {
        localThis.$store.dispatch("setMessages").finally(() => {
          localThis.content = localThis.getLangContent();
          content = localThis.getLangContent();
        });
      }
      if (content) {
        localThis.isReady = true;
        localThis.content = content;
      }
    }
  },
  watch: {
    $route() {
      // this.loadContent();
      this.updateLanguage();
    }
  },
  computed: {
    ...mapGetters(['getMessage']),
    allContent(){
      const template = this.template;

      return this.getMessage(template);
    }
  }
};
</script>
<style scoped lang="scss">
.close-box {
  display: none;
  transition: display 1s;
}
</style>
