import axios from "axios";

const apiConfig = {
  baseURI: `${process.env.VUE_APP_HDRUPAL_BASE_URL}`,
};

async function getData(lang) {
  const contentURI = `${lang}/kis/NMqNxPm8tpGZ3Rwj/api/node/message`;
  const params = {
    jsonapi_include: "1",
    "filter[field_gid]": process.env.VUE_APP_HDRUPAL_GID
  };

  return await axios.get(`${apiConfig.baseURI}/${contentURI}`, {
    params
  });
}

async function getContent() {
  return await getData("en");
}

export { getContent, getData };
