<template>
  <section v-if="sectionData" class="team-container">
    <div class="wrapper">
      <h2
        v-if="sectionData.title"
        v-html="sectionData.title"
        class="display--1 no-break team-heading"
      ></h2>

      <div
        v-for="(team, index) in sectionData.list"
        :key="index"
        class="team-member-container"
      >
        <div :class="[index % 2 === 0 ? '' : 'reverse']" class="row">
          <div
            v-if="team.image"
            class="col-lg-4 col-xs-12 tt--col"
          >
            <div class="member-foto">
              <img :src="team.image.url" alt="" />
            </div>
          </div>
          <div class="col-lg-8 col-xs-12 tt--col">
            <div class="member-detail">
              <h3
                v-if="team.name"
                v-html="team.name"
                class="member-name"
              ></h3>
              <div
                v-if="team.title"
                v-html="team.title"
                class="memeber-job"
              ></div>
              <div
                v-if="team.description"
                v-html="team.description"
                class="member-info"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "OurTeamTeams",
  props: {
    sectionData: {
      type: Object,
      required: true
    }
  },
  methods: {}
};
</script>

<style scoped></style>
