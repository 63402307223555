<template>
    <section v-if="sectionData" class="section--wl" id="remote">
        <div class="wrapper">
            <div class="flex-row has--padding">
                <div class="col-lg-6 col-xs-12 swl-col">
                    <div class="swl-content">
                        <h2
                                v-if="sectionData.title"
                                v-html="sectionData.title"
                                class="display--1"
                        ></h2>
                        <div
                                v-if="sectionData.description"
                                v-html="sectionData.description"
                                class="swl-lead"
                        ></div>
                    </div>
                </div>
                <div class="col-lg-6 col-xs-12 swl-col">
                    <div class="swl-content-2">
                        <ul v-if="sectionData.items" class="ul-checkmark">
                            <li v-for="item in sectionData.items" :key="item.id">
                                <a
                                        v-if="item.link"
                                        :href="item.link.uri"
                                        v-html="item.title"
                                >
                                </a>
                                <span v-else v-html="item.title"></span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: 'BankingTitleContentList',
        props: {
            sectionData: {
                type: Object,
                required: true
            }
        },
        methods: {
            getBackground(image) {
                const imageData = image
                // .replace('public://', '')
                const fullUrl = this.$store.state.hDrupalBaseUrl + '/' + imageData

                return fullUrl.replace('/public://', '/sites/default/files/')
            },
            fixUrl(uri) {
                const newUri = uri.replace('internal:', '')
                return newUri
            }
        }
    }
</script>

<style scoped></style>
