<template>
  <section v-if="sectionData" class="section--solutions">
    <div class="wrapper">
      <div class="flex-row has--padding">
        <div class="col-lg-6 col-xs-12 ss--col">
          <div class="ss--content">
            <h2
                    v-if="sectionData.title"
                    v-html="sectionData.title"
                    class="display--1"
            ></h2>
            <div
                    v-if="sectionData.description"
                    v-html="sectionData.description"
                    class="ss--desc"
            ></div>
            <div v-if="sectionData.link" class="ss--cta">
              <a
                      :href="sectionData.link.uri"
                      class="primary-link-button"
              >
                {{ sectionData.link.title }}
              </a>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-xs-12 ss--col">
          <div class="ss--content" v-if="sectionData.listIcons">
            <div  class="ss--icon-list">
              <div
                      v-for="iconItem in sectionData.listIcons"
                      :key="iconItem.id"
                      class="ss--list-icon"
              >
                <div class="ss--icon-image">
                  <img
                          :src="iconItem.icon.url"
                          :alt="iconItem.icon.alt"
                  />
                </div>
                <h3 class="ss--list-title">
                  {{ iconItem.title }}
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <span  id="benefits-wp-block"></span>
  </section>
</template>

<script>
export default {
  name: "bankingContentTop",
  props: {
    sectionData: {
      type: Object,
      required: true
    }
  },
  mounted(){
    setTimeout(() => {
        var elem = document.querySelector('.ostitle');
            if (elem) {
                elem.onclick = function (e) {
                  var menu = document.querySelector('.our-services');
                  menu.classList.toggle('is--active');
                  e.preventDefault();
                }
            }
        },
            300);
  },
  methods: {},
  computed: {}
};
</script>

<style scoped></style>
