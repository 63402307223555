<template>
    <div class="header-content-section">
        <media :query="{minWidth: 1024}">
            <section
                    v-if="sectionData"
                    class="hero-section hs-1"
                    :class="[sectionData.video ? 'hs-video' : '']"
            >
                <video-bg :sources="[sectionData.video.url, sectionData.video_2.url]">
                    <div class="wrapper">
                        <div class="hero-section-inner">
                            <div class="hero-content">
                                <h1
                                        v-if="sectionData.title"
                                        v-html="sectionData.title"
                                        class="hero-title display--1"
                                ></h1>
                            </div>
                            <a
                                    class="anchor-next"
                                    arible-label="Scroll Down"
                                    aria-hidden="true"
                                    v-scroll-to="'#next'"
                            ></a>
                        </div>

                    </div>
                </video-bg>
            </section>
        </media>
        <media :query="{maxWidth: 1023}">
            <section
                    v-if="sectionData"
                    :style="{
      'background-image': 'url(' + sectionData.bg_mobile + ')'
    }"
                    class="hero-section hs-1"
            >
                <div class="bg-shadow-header">
                    <div class="wrapper">
                        <div class="hero-section-inner">
                            <div class="hero-content">
                                <h1
                                        v-if="sectionData.title"
                                        v-html="sectionData.title"
                                        class="hero-title display--1"
                                ></h1>
                            </div>
                        </div>
                        <a

                                class="anchor-next"
                                arible-label="Scroll Down"
                                aria-hidden="true"
                                v-scroll-to="'#next'"
                        ></a>
                    </div>
                </div>
            </section>
        </media>
    </div>
</template>

<script>
    import VideoBg from 'vue-videobg'
    import Media from 'vue-media'
    export default {
        name: "heroBanner",
        props: {
            sectionData: {
                type: Object,
                required: true,
            }
        },
        components: {
            VideoBg,
            Media
        }
    }
</script>

<style lang="scss">
    .hero-section.hs-1.hs-video{
        background: transparent!important;
        height: auto;
        padding-bottom: 0!important;
        .VideoBg .VideoBg__content{
            background: rgba(0,0,0,0.5)!important;
        }
    }
    .bg-shadow-header{
        background: rgba(0,0,0,0.5)!important;
    }
</style>