<template>
  <main role="main">
    <h2>ESte es si</h2>
    <div v-if="general != null">
      <c-section-banner
        :sectionData="getSectionBanner(general[0])"
      ></c-section-banner>
      <c-section-content-top
        :sectionData="getSectionContentTop(general[0])"
      ></c-section-content-top>
      <c-section-title-content-boxes
        :sectionData="getSectionTitleContentBoxes(general[0])"
      ></c-section-title-content-boxes>
      <c-section-image-slider
        :sectionData="getSectionImageSlider(general[0])"
      ></c-section-image-slider>
      <c-section-title-content-boxes-bottom
        :sectionData="getSectionTitleContentBoxesBottom(general[0])"
      ></c-section-title-content-boxes-bottom>
      <c-section-gallery
        :sectionData="getSectionGallery(general[0])"
      ></c-section-gallery>
    </div>
    <speed-bump v-if="general" />
  </main>
</template>

<script>
import * as contentAPI from "@/api/content";
export default {
  name: "Home",
  data() {
    return {
      default: {
        uuid: "39ed40ec-4fdb-43f4-854e-05764789cfb5",
        nid: 678,
        title: "Home",
        type: "fb_general_page"
      },
      templateName: "Home",
      language: this.$i18n.locale,
      content: null,
      isReady: false,
      helpers: this.$helperList
    };
  },
  created() {
    this.getContent();
    // this.getCalendar();
  },
  computed: {
    languageChange() {
      return this.$i18n.locale;
    }
  },
  methods: {
    getContent() {
      this.isReady = false;
      let content = null;
      contentAPI
        .getContent({
          type: this.default.type,
          uuid: this.default.uuid,
          lang: this.language
        })
        .then(data => {
          console.log("THEN DATA", data);
          content = data.data.data;
        })
        .catch(error => {
          console.log("ERROR ON setContent function", error);
          content = null;
        })
        .finally(() => {
          this.content = content;
          this.isReady = true;
          const currentClass = this.$helperList.setContentTypeClass(
            this.default.type
          );
          document.body.classList.add(currentClass);
        });
    }
  },
  watch: {
    $route() {
      this.language = this.$i18n.locale;
      this.getContent();
    }
  }
};
</script>

<style scoped></style>
