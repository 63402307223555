<template>
  <section v-if="sectionData" class="section-about-1">
    <div class="wrapper">
      <div class="flex-row row-reverse">
        <div class="col-lg-7">
          <div class="sa1-content">
            <div
              v-if="sectionData.subtitle"
              v-html="sectionData.subtitle"
              class="display--4"
            ></div>
            <h2
              v-if="sectionData.title"
              v-html="sectionData.title"
              class="display--1 no-break display--inner"
            ></h2>
            <div
              v-if="sectionData.description"
              v-html="sectionData.description"
            ></div>
            <p v-if="sectionData.link" class="pt20">
              <router-link
                :to="sectionData.link.uri"
                class="primary-link-button"
              >
                {{ sectionData.link.title }}
              </router-link>
            </p>
          </div>
        </div>
        <div class="col-lg-5 col-xs-12" v-if="sectionData.image">
          <div class="sa1-image">
            <img :src="sectionData.image.url" alt="About image" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'AboutUsContentTop',
  props: {
    sectionData: {
      type: Object,
      required: true
    }
  },
  methods: {
    getBackground(image) {
      const imageData = image
      // .replace('public://', '')
      const fullUrl = this.$store.state.hDrupalBaseUrl + '/' + imageData

      return fullUrl.replace('/public://', '/sites/default/files/')
    },
    fixUrl(uri) {
      const newUri = uri.replace('internal:', '')
      return newUri
    }
  }
}
</script>

<style scoped></style>
