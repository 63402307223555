<template>
<main role="main">
  <div v-if="isReady">
    <hero-banner :sectionData="helpers.getSectionBanner(content)" />
    <content-top :sectionData="helpers.getSectionContentTop(content)" />
    <title-content-boxes :sectionData="helpers.getSectionWTitleContentBoxes(content)" />
    <image-slider :section-data="helpers.getSectionImageSlider(content)" />
    <title-content-boxes-bottom :section-data="helpers.getSectionTitleContentBoxesBottom(content)" />
    <gallery :section-data="helpers.getSectionGallery(content)" />
  </div>
  <speed-bump v-if="isReady" />
  </main>
</template>

<script>
import * as contentAPI from "@/api/content";
import speedBump from "@/components/sections/speedBump";
import HeroBanner from "../components/general/heroBanner";
import ContentTop from "../components/general/contentTop";
import TitleContentBoxes from "../components/general/titleContentBoxes";
import ImageSlider from "../components/general/imageSlider";
import TitleContentBoxesBottom from "../components/general/titleContentBoxesBottom";
import Gallery from "../components/general/gallery";
export default {
  name: "HomePage",
  title () {
    return `Platinum | FirstBank — ${this.title}`
  },
  components: {
    Gallery,
    TitleContentBoxesBottom,
    ImageSlider,
    TitleContentBoxes,
    ContentTop,
    HeroBanner,
    speedBump,
  },
  data() {
    return {
      baseUri: "/home",
      theme: "home-page",
      title: this.$route.meta.title,
      language: this.$i18n.locale,
      content: null,
      isReady: false,
      helpers: this.$helperList,
      default: {
        uuid: "39ed40ec-4fdb-43f4-854e-05764789cfb5",
        nid: 678,
        title: "Home",
        type: "fb_general_page"
      },
    };
  },
  mounted() {
    document.body.classList.remove("inner--page");
    document.body.classList.add("home-page");
  },
  created() {
    this.getContent();
  },
  computed: {
    languageChange() {
      return this.$i18n.locale;
    },
  },
  watch:{
    $route() {
      this.language = this.$i18n.locale;
      this.getContent();
    },
  },
  methods: {
    getContent() {
      this.isReady = false;
      const localThis = this;
      let content = null;
      contentAPI
              .getContent({
                type: this.default.type,
                uuid: this.default.uuid,
                lang: this.language
              })
              .then(data => {
                content = data.data.data;
                localThis.content = content;
                localThis.isReady = true;
              })
              .catch(error => {
                console.log("ERROR ON setContent function", error);
                content = null;
                localThis.content = null
                localThis.isReady = false;
              })
              .finally(() => {
                // const currentClass = this.$helperList.setContentTypeClass(
                //         this.default.type
                // );
                document.body.classList.add('home-page');
              });
    }
  }
};
</script>

<style scoped lang="scss">
  .wait-connection {
    min-height: 80vh;
  }
</style>
