<template>
  <!--  Pending add Gallery List of Images -->
  <section
    v-if="sectionData.background"
    class="hs-6"
  >
    <div class="banner-outer"
    :style="{
      'background-image':
        'url(' + sectionData.background.url + ')'
    }">
      <div class="banner-inner">
        <div class="wrapper">
          <div class="banner-content">
            <h2
              v-if="sectionData.title"
              v-html="sectionData.title"
              class="display--1"
            ></h2>
            <router-link :to="galleryLink"
              class="primary-link-button span-link"
              >{{ sectionData.gallery_title }}</router-link
            >
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  export default {
  name: 'Gallery',
  data() {
      return {
        galleryLink: '/en/about-us#gallery',
        language: this.$i18n.locale,
      }
  },
  props: {
    sectionData: {
      type: Object,
      required: true
    }
  },
  methods: {
    updateLanguage() {
      this.language = this.$i18n.locale;
      this.galleryLink = "/es/sobre-nosotros#gallery";
      if (this.language === "en") {
        this.galleryLink = '/en/about-us#gallery';
      }
    },
    getBackground(image) {
      const imageData = image
      // .replace('public://', '')
      const fullUrl = this.$store.state.hDrupalBaseUrl + '/' + imageData

      return fullUrl.replace('/public://', '/sites/default/files/')
    },
    getMediaData(list){
      const listData = list.map((item) => {
        return {
          thumb: this.getBackground(item.field_pg_image_text),
          src: this.getBackground(item.field_pg_image_text),
          caption: item.field_pg_title_w_format.processed
        }
      })
      return listData
    }
  },
    watch: {
      $route() {
        this.language = this.$i18n.locale;
        this.updateLanguage();
      }
    },
    created() {
      this.updateLanguage();
    },
}
</script>

<style scoped lang="scss">
  .span-link{
    cursor: pointer;
    &:hover{
      text-decoration: underline;
    }
  }
</style>
