export default {
  setLinkField(field, lang) {
    if (!field) {
      return null;
    }

    return {
      title: field.title,
      uri: this.getMenuUrl(field.uri, lang)
    };
  },
  setTextAreaField(field) {
    if (!field) {
      return null;
    }

    return field.value;
  },
  setIconField(field) {
    if (!field) {
      return null;
    }
    if (!field.uri) {
      return null;
    }
    return {
      url: this.getFullImagePath(field.uri.url),
      alt: field.meta.alt
    };
  },
  getFullImagePath(uri) {
    // console.log(uri);
    if (uri) {
      return `${process.env.VUE_APP_HDRUPAL_BASE_URL}${uri}`;
    }

    return null;
  },
  getSectionBanner(contentData) {
    const localThis = this;
    return {
      subtitle: localThis.setTextAreaField(contentData.field_header_subtitle),
      title: localThis.setTextAreaField(contentData.field_header_title),
      description: localThis.setTextAreaField(
        contentData.field_header_description
      ),

      bg: localThis.setIconField(contentData.field_header_bg),
      bg_mobile: localThis.setIconField(contentData.field_header_bg_mobile),
      image_text: localThis.setTextAreaField(
        contentData.field_header_image_text
      ),
      video: localThis.getMediaVideo(contentData.field_video),
      video_2: localThis.getMediaVideo(contentData.field_video_2)
    };
  },
  getMediaVideo(video) {
    if (!video.field_media_video_file) {
      return null;
    }
    return this.setIconField(video.field_media_video_file);
  },
  getIconLIstData(fieldData) {
    if (!fieldData) {
      return null;
    }
    if (!Array.isArray(fieldData)) {
      return null;
    }

    const localThis = this;

    const data = fieldData.map(function(fieldItem) {
      return {
        icon: localThis.setIconField(fieldItem.field_pg_icon),
        link: localThis.setLinkField(fieldItem.field_pg_link),
        title: localThis.setTextAreaField(fieldItem.field_pg_title_w_format),
        id: fieldItem.id
      };
    });

    return data;
  },
  getAddressGroup(contentData) {
    const localThis = this;

    if (
      Array.isArray(contentData.field_items_1) &&
      contentData.field_items_1.length > 0
    ) {
      return contentData.field_items_1.map(function(fieldItem) {
        return {
          title: fieldItem.field_pg_title,
          content: localThis.setTextAreaField(fieldItem.field_pg_description)
        };
      });
    }

    return null;
  },
  getSectionContentTop(contentData) {
    const localThis = this;
    return {
      subtitle: localThis.setTextAreaField(contentData.field_ct_subtitle),
      title: localThis.setTextAreaField(contentData.field_ct_title),
      description: localThis.setTextAreaField(contentData.field_ct_description),
      link: localThis.setLinkField(
        contentData.field_ct_link,
        contentData.langcode
      ),
      image: localThis.setIconField(contentData.field_ct_image_file),
      listTitle: contentData.field_ctl_list_title,
      listIcons: localThis.getIconLIstData(contentData.field_icons_list)
    };
  },
  getSectionTitleContentList(contentData) {
    const localThis = this;
    return {
      title: localThis.setTextAreaField(contentData.field_ccs_title),
      description: localThis.setTextAreaField(
        contentData.field_ccs_description
      ),
      items: localThis.getListItem(contentData.field_ccs_items)
    };
  },
  getListItem(fieldData) {
    if (!fieldData) {
      return null;
    }
    if (!Array.isArray(fieldData)) {
      return null;
    }
    // console.log("Fields data", fieldData);
    const localThis = this;
    const data = fieldData.map(function(fieldItem) {
      return {
        id: fieldItem.id,
        title: fieldItem.field_pg_title,
        link: localThis.setLinkField(fieldItem.field_pg_link),
        description: localThis.setTextAreaField(fieldItem.field_pg_description)
      };
    });

    return data;
  },
  getFBMemberItem(fieldData) {
    if (!fieldData) {
      return null;
    }
    if (!Array.isArray(fieldData)) {
      return null;
    }
    const localThis = this;
    const data = fieldData.map(function(fieldItem) {
      return {
        id: fieldItem.id,
        name: fieldItem.field_pg_sub_title,
        title: fieldItem.field_pg_title,
        image: localThis.setIconField(fieldItem.field_pg_image),
        description: localThis.setTextAreaField(fieldItem.field_pg_description)
      };
    });

    return data;
  },
  getSectionTeam(contentData) {
    return {
      title: this.setTextAreaField(contentData.field_team_title),
      list: this.getFBMemberItem(contentData.field_team_list)
    };
  },
  getSectionTitleContentBoxes(contentData) {
    return {
      title: this.setTextAreaField(contentData.field_cc_title),
      listContent: this.getFbContentBox(contentData.field_box_content)
    };
  },
  getSectionWTitleContentBoxes(contentData) {
    return {
      title: this.setTextAreaField(contentData.field_cc_title),
      listContent: this.getFbContentBox(contentData.field_cc_content_list)
    };
  },
  setTemplateClass(template) {
    if (template === "faq") {
      return "faq-page";
    }

    return template;
  },
  getSectionTitleImageContent(contentData) {
    const localThis = this;
    return {
      title: localThis.setTextAreaField(contentData.field_bs_title),
      image: localThis.setIconField(contentData.field_bs_image_file),
      link: localThis.setLinkField(contentData.field_bs_link),
      content: localThis.setTextAreaField(contentData.field_bs_content)
    };
  },
  getFbContentBox(fieldData) {
    if (!fieldData) {
      return null;
    }
    if (!Array.isArray(fieldData)) {
      return null;
    }
    const localThis = this;
    const data = fieldData.map(function(fieldItem) {
      return {
        id: fieldItem.id,
        title: localThis.setTextAreaField(fieldItem.field_pg_title_w_format),
        image: localThis.setIconField(fieldItem.field_pg_image),
        link: localThis.setLinkField(fieldItem.field_pg_link),
        description: localThis.setTextAreaField(fieldItem.field_pg_description)
      };
    });

    return data;
  },
  getSectionImageSlider(contentData) {
    return {
      image_list: this.getFbImageElement(contentData.field_image_list)
    };
  },
  getFBGalleryList(fieldData) {
    if (!fieldData) {
      return null;
    }
    if (!Array.isArray(fieldData)) {
      return null;
    }

    const localThis = this;
    const data = fieldData.map(function(fieldItem) {
      const url = fieldItem.field_pg_link ? fieldItem.field_pg_link.uri : null;
      let image_list = [];
      if(Array.isArray(fieldItem.field_pg_image_files)) {
        image_list = fieldItem.field_pg_image_files.map(item => {
          return localThis.setIconField(item);
        });
      }

      return {
        id: fieldItem.id,
        title: fieldItem.field_pg_title,
        description: localThis.setTextAreaField(fieldItem.field_pg_description),
        image_description: fieldItem.field_pg_image_description,
        url_link: url,
        image_list: image_list,
        modal:
          "meta" in fieldItem.field_gallery
            ? fieldItem.field_gallery.meta.drupal_internal__target_id
            : null,
        description_list: fieldItem.field_pg_image_list_description
      };
    });

    return data;
  },
  getMediaImageListContent(fieldData) {
    if (!fieldData) {
      return null;
    }

    if (!Array.isArray(fieldData)) {
      return null;
    }

    const localThis = this;

    return fieldData.map(function(fieldItem) {
      const image = localThis.setIconField(fieldItem.field_pg_image);
      const video = localThis.setIconField(fieldItem.field_pg_video);
      const youtube = localThis.setLinkField(fieldItem.field_youtube);
      let type = "video";
      let url = video;
      if (image) {
        type = "image";
        url = image;
      }

      if (youtube) {
        type = "youtube";
        url = youtube;
      }
      return {
        type: type,
        file: url
      };
    });
  },
  getMediaGalleryList(fieldData) {
    if (!fieldData) {
      return null;
    }

    const localThis = this;
    const content = {
      id: fieldData.drupal_internal__nid,
      title: localThis.setTextAreaField(fieldData.field_header_title),
      description: localThis.setTextAreaField(fieldData.body),
      mediaList: localThis.getMediaImageListContent(
        fieldData.field_media_gallery
      )
    };

    return content;
  },
  getFbImageElement(fieldData) {
    if (!fieldData) {
      return null;
    }
    if (!Array.isArray(fieldData)) {
      return null;
    }

    const localThis = this;
    const data = fieldData.map(function(fieldItem) {
      return {
        id: fieldItem.id,
        title: localThis.setTextAreaField(fieldItem.field_pg_title_w_format),
        image: localThis.setIconField(fieldItem.field_pg_image)
      };
    });

    return data;
  },
  getSectionTitleContentBoxesBottom(contentData) {
    return {
      cb_title: this.setTextAreaField(contentData.field_cb_title),
      listContent: this.getFbContentBox(contentData.field_box_content)
    };
  },
  getSectionGalleryList(contentData) {
    const localThis = this;
    return {
      subTitle: localThis.setTextAreaField(contentData.field_gallery_subtitle),
      title: localThis.setTextAreaField(contentData.field_gallery_title),
      description: localThis.setTextAreaField(
        contentData.field_gallery_description
      ),
      openLink: contentData.field_gallery_label_link,
      list: localThis.getFBGalleryList(contentData.field_gallery_item)
    };
  },
  getSectionGallery(contentData) {
    const localThis = this;
    return {
      title: localThis.setTextAreaField(contentData.field_ccb_title),
      background: localThis.setIconField(contentData.field_ccb_gallery_bg),
      gallery_title: contentData.field_ccb_open_gallery_title,
      gallery_content: localThis.getFbImageElement(
        contentData.field_ccb_gallery_content
      )
    };
  },
  checkMenu(url) {
    if (this.isExternal(url)) {
      window.location.replace(url);
    }
  },
  scrollToTop() {
    window.scrollTo(0, 0);
  },
  checkMenuFooter(url) {
    this.checkMenu(url);
    this.scrollToTop();
  },
  isExternal(url) {
    return !!url.includes("http");
  },
  getMenuUrl(uri) {
    let cleanURI = uri.replace("internal:/es/", "/es/");
    if (uri.includes("internal:/es/")) {
      cleanURI = uri.replace("internal:/es/", "/es/");
      return cleanURI;
    }
    if (uri.includes("internal:/en/")) {
      cleanURI = uri.replace("internal:/en/", "/en/");
      return cleanURI;
    }
    if (uri.includes("internal:#")) {
      cleanURI = uri.replace("internal:#", `#`);
      return cleanURI;
    }
    if (uri.includes("internal:/")) {
      cleanURI = uri.replace("internal:/", `/`);
      return cleanURI;
    }
    return uri;
  },
  setContentTypeClass(type) {
    if (type === "article") {
      return "page-node-type-article";
    }

    if (type === "basic_page") {
      return "page-node-type-page";
    }
  }
};
